<template>
  <div class="body">
    <div class="top-box-top">
        <top />
    </div>
    <div class="bottom-box">
      <basecom />
      <div class="list">
        <div class="medical-list">
          <funListTable
            :row-class-name="tableRowClassName"
            :header-cell-style="{
            background:'#000',color:'#fff'}"
            :tableData="this.funlogic.loadFunList()"
            :tableHeader="this.funlogic.opHeader()"
            :lazyLoad="this.funlogic.getChildFunList"
          >
              <template v-slot:btn="slotProps">
              <el-button
                type="primary"
                @click.stop="addFunc(slotProps.row.id, slotProps.row.functionName)"
              >
                  添加子功能
              </el-button>
              </template>
          </funListTable>
        </div>
      </div>
      <el-drawer
        :model-value="addfunDial"
        title="添加子功能"
        :before-close="nosureAddFun"
      >
        <el-form 
          ref="addFunc" 
          :model="funFrom" 
          :rules="this.funlogic.rules"  
          style="overflow: auto;height: 58vh;margin-top: 3vh;"
          >
          <el-row
            class="add-row"
          >
          <el-col :span="24" :md="20">
            <span><i>*</i>功能名称：</span>
            <el-form-item prop="fName">
              <el-input
                class="admin_tel"
                v-model="funFrom.fName"
                maxlength="30"
                placeholder="请输入功能名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>上级功能：</span>
            <el-form-item prop="fpName">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fpName"
              maxlength="30"
              :disabled="true"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24" :md="20">
            <span><i>*</i>功能区域：</span>
            <el-form-item prop="functionAreaType">
              <el-select
              v-model="funFrom.functionAreaType"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                v-for="item in this.funlogic.TF2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" :md="20">
            <span class="electrode"><i>*</i>&nbsp;&nbsp;功能控制器：</span>
            <el-form-item prop="fController">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fController"
              placeholder="请输入功能控制器"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>激活状态：</span>
            <el-form-item prop="status">
              <el-select
              v-model="funFrom.status"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                v-for="item in this.funlogic.TF1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能方法：</span>
            <el-form-item prop="fAction">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fAction"
              placeholder="请输入功能方法"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能类型：</span>
            <el-form-item prop="fType">
              <el-select
                v-model="funFrom.fType"
                placeholder="请选择"
                class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.TFBtn"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能图标：</span>
            <el-form-item prop="fIcon">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fIcon"
              maxlength="20"
              placeholder="功能图标可为空"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能排序：</span>
            <el-form-item prop="fOrder">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fOrder"
              maxlength="30"
              type="Number"
              placeholder="请输入功能排序值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>系统功能：</span>
            <el-form-item prop="isMainFunc">
              <el-select
              v-model="funFrom.isMainFunc"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.TF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>显示状态：</span>
            <el-form-item prop="isShow">
              <el-select
              v-model="funFrom.isShow"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.Show"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能描述：</span>
            <el-form-item prop="fDescription">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fDescription"
              maxlength="100"
              placeholder="请输入功能描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </el-form>
        <div class="sureBtn" style=" margin-top: 5vh !important;">
          <el-button class="qx" @click="nosureAddFun('addFunc')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureAddFun('addFunc')" type="primary"
            ></el-button
          >
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import funListTable from "@/components/listComponents/treeList";
import basecom from "@/components/base";
import top from "@/components/top.vue";
export default {
  name: "addFunc",
  components: { funListTable,basecom,top },
  data() {
    return {
      addfunDial: false,
      funFrom: {},
      mbFpid: "",
    };
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
    },
    nosureAddFun() {
      //弹窗关闭
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addfunDial = false;
          done();
        })
        .catch((_) => {});
    },
    sureAddFun(ref) {
      //下面为验证,需要设置form的ref
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let funFrom = this.funFrom;
          //这里写请求
          funFrom.fpid = this.mbFpid;
          funFrom.fOrder = Number(funFrom.fOrder);
          delete funFrom.fpName;
          this.axios
            .post(
              this.baseURL + "Function/FunctionAdd",
              funFrom,
              this.headers()
            )
            .then((res) => {
              if(res.data.data.flg){
                this.resolveData(res.data, "add");
                this.addfunDial = false;
              }else{
                this.resolveData(res.data, "add");
              }
            });
        } else {
          return false;
        }
      });
    },
    addFunc(id, name) {
      this.addfunDial = true;
      this.funFrom = {};
      this.funFrom.fpName = name;
      //data中加入当前所选功能的fpid
      this.mbFpid = id;
      //数据清空
      //验证提示消除
      this.resetForm("addFunc");
    },
    //清除验证提示方法
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>
  