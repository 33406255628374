<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <friendlySearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></friendlySearch>
                  </div>
                  <friendlyLinklist
                  :header-cell-style="{ 
                  background:'#000',color:'#fff'}"
                  :tableHeader="this.friendlylogic.opHeader()"
                  :tableData="friendlyLinkList"
                  :page="this.friendlylogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="danger" @click="editModule(slotProps.row)">
                              删除友情链接
                          </el-button>
                      </template>
                  </friendlyLinklist>
                  <pager
                  :page="this.friendlylogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import friendlyLinklist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import friendlySearch from "@/components/searchComponents/friendlySearch.vue";
  export default {
    components: {
      friendlyLinklist,
      pager,
      friendlySearch,
      top,
      basecom
    },
    data() {
      return {
        friendlyLinkList: [],
        page: 1,
        numTotal: 0,
      };
    },
    mounted() {
      this.search();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page) {
        this.friendlylogic.searchObj.page = page;
        this.friendlylogic.getFriendlyList().then((res) => {
          this.friendlyLinkList = res.friendlyLinkList;
          this.numTotal = res.total;
        });
      },
      sureDeleteArticle() {
        var aa = {
          id:this.mbID
        }
        this.axios(
            {   method: "delete",
                url: this.baseURL + "FriendlyLinks/FriendlyLinksDelete",
                data: aa,
                headers: this.header(),
            }
        ).then((res) => {
          this.resolveData(res.data, "delete");
        });
      },
      editModule(row) {
        this.mbID = row.id;
        this.$confirm('确定要删除该友情链接吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sureDeleteArticle()
        })
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  