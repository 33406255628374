<template>
  <div class="body">
    <div class="top-box-top">
        <top />
    </div>
    <div class="bottom-box">
      <basecom />
      <div class="list">
          <div class="medical-list">
              <div class="search">
                  <userSearch
                  :search="search"
                  :reset="reset"
                  :numTotal="numTotal"
                  ></userSearch>
              </div>
              <userlist
              :header-cell-style="{ 
              background:'#000',color:'#fff'}"
              :tableHeader="this.userlogic.opHeader()"
              :tableData="userList"
              :page="this.userlogic.searchObj.page"
              :row-class-name="tableRowClassName"
              >
                  <template v-slot:btn="slotProps">
                      <el-button type="primary" @click="editagency(slotProps.row)">
                      分配机构
                      </el-button>
                  </template>
              </userlist>
              <pager
              :page="this.userlogic.searchObj.page"
              :numTotal="numTotal"
              :handleCurrentChange="handleCurrentChange"
              >
              </pager>
          </div>
      </div>
      <el-drawer
        :model-value="editAgency"
        title="请选择需要分配的机构"
        :before-close="nosureEditAgency"
      >
        <el-row class="assign-row">
          <el-col>
            <el-tree
              :data="agencyList"
              ref="agencyTree"
              node-key="id"
              :default-checked-keys="userAgencyList"
              :check-strictly="true"
              :props="defaultProps"
              @node-click="handleNodeClick"
              show-checkbox
            >
            </el-tree>
          </el-col>
        
        </el-row>
        <div class="sureBtn assignBtn" style="margin-top:-0.5rem;">
          <el-button class="qx" @click="nosureEditAgency('EditAgency')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureEditAgency('EditAgency')" type="primary"
            ></el-button
          >
        </div>
      </el-drawer>
    </div>
  </div>
    
</template>

<script>
  import basecom from "@/components/base";
import userlist from "@/components/listComponents/pagerList";
import top from "@/components/top.vue";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  name: "assignRole",
  components: {
    userlist,
    pager,
    userSearch,
    top,
    basecom
  },
  data() {
    return {
      editAgency: false,
      agencyList: [],
      userList: [],
      page: 1,
      pageSize: 10,
      formList: [],
      numTotal: 0,
      mbID: "",
      userAgencyList: [],
      defaultProps: {
        children: "children",
        label: "agencyName",
      },
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
    handleCurrentChange: function (page = 1) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
      this.getRoleList();
    },
    search() {
      this.handleCurrentChange();
    },
    nosureEditAgency() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editAgency = false;
          done();
        })
        .catch((_) => {});
    },
    sureEditAgency() {
      let aa = {
        userId: this.mbID,
        agencyIdList: this.$refs.agencyTree.getCheckedKeys(),
      };
      this.axios
        .post(this.baseURL + "User/AssignAgency", aa, this.headers())
        .then((res) => {
          this.resolveData(res.data, "assign");
        });
    },
    editagency(row) {
      this.editAgency = true;
      this.mbID = row.id;
      let aa = {
        id: row.id,
      };
      this.userAgencyList = [];
      this.axios
        .post(this.baseURL + "User/GetUserAgencyList", aa, this.headers())
        .then((res) => {
          this.$refs.agencyTree.setCheckedKeys([]);
          let data = this.resolveData(res.data);
          let arr = [];
          data.agencyList.map((item) => {
            arr.push(item.id);
          });
          this.userAgencyList = arr;
        });
    },
    handleNodeClick() {
      console.log(this.$refs.agencyTree.getCheckedKeys());
    },
    getRoleList() {
      let agencys = this.getAgency();
      agencys.forEach((role) => {
        let aa = {
          apid: role.id,
        };
        this.axios
          .post(this.baseURL + "Agency/AgencyList", aa, this.headers())
          .then((res) => {
            let data = this.resolveData(res.data);
            role.children = data.agencyList;
            let arr = [];
            data.agencyList.map((item) => {
                arr.push(item.id);
            });
            console.log(aa)
            this.userAgencyList = arr;
          });
        this.agencyList.push(role);
      });
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>

<style>
</style>