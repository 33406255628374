<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                    <articleSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></articleSearch>
                    </div>
                    <articlelist
                    :header-cell-style="{ 
                    background:'#000',color:'#fff'}"
                    :tableHeader="this.articlelogic.listHeader()"
                    :tableData="articleList"
                    :page="this.articlelogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    ></articlelist>
                    <pager
                    :page="this.articlelogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import articlelist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import articleSearch from "@/components/searchComponents/articleSearch.vue";
export default {
    components: {
        basecom,
        articlelist,
        pager,
        articleSearch,
        top
    },
    data() {
        return {
            articleList: [],
            page: 1,
            numTotal: 0,
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.articlelogic.searchObj.page = page;
            this.articlelogic.getArticleList().then((res) => {
                this.articleList = res.articleList;
                this.numTotal = res.total;
                this.articleList.forEach(element => {
                    element.isShows = ''
                    switch(element.isShow){
                        case true:
                            element.isShows = "已启用";
                        break;
                        case false:
                            element.isShows = "未启用";
                        break;
                    }
                });
            });
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
    },
    };
</script>