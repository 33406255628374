<template>
    <div class="top-box-top">
      <top />
    </div>
    <div class="bottom-box">
        <basecom />
        <div class="list">
            <div class="medical-list">
            <categorylist
                :default-expanded-keys="[]"
                :row-class-name="tableRowClassName"
                :header-cell-style="{ 
                background:'#000',color:'#fff'}"
                :tableData="this.categoryList"
                :tableHeader="this.categorylogic.opHeader()"
                :lazyLoad="this.categorylogic.getChildCategoryList"
            >
                <template v-slot:btn="slotProps">
                <el-button
                    type="primary"
                    @click.stop="addCategory(slotProps.row.id)"
                    v-if="slotProps.row.parentId != '00000000-0000-0000-0000-000000000000'"
                >
                    页面设计
                </el-button>
                </template>
            </categorylist>
            </div>
        </div>
       
    </div>
    <el-drawer
        id="design-drawer"
        title="分类页面设计"
        :model-value="addCategoryDial"
        :before-close="nosureAddCategory"
        >
            <div class="module-dialog">
                <transition name="left">
                    <div class="dialog-left" :class="{'dialog-left-biu':this.dialogright },{'dialog-left-biu':this.editDialogright} ">
                        <div class="dialog-left-title">
                            <div>
                                <span>页面预览</span> 
                                <el-button v-if="!this.dialogright" @click="addmoduleRight()" type="success" circle style="
                                height: 2rem;
                                width: 2rem;
                                margin-left: 0.2rem;
                                ">+</el-button>
                            </div>
                        </div>
                        <div class="dialog-left-data">
                            <div v-for="item in categoryModuleDesignList" @click="editmoduleRight(item)" style="display: flex;justify-content: space-between;width:auto;max-width: 30rem;">
                                <div>
                                    {{ item.moduleName }}
                                </div>  
                                <el-button type="danger" @click="deletemodule(item.id)">删除</el-button>
                            </div>
                        </div>
                    </div>
                </transition>

                <!-- 添加 -->
                <transition name="right" v-if="dialogright">
                    <div class="dialog-right">
                        <div class="dialog-right-title">
                            添加模块
                        </div>
                        <el-form id="fromtopgo" ref="AddArticle" :model="moduleForm" :rules="this.categorylogic.designrules" class="medical-from" style="height: 80%;overflow: auto;">
                            <el-row
                                class="article-add-row"
                                style="height: 30rem;margin-left: 1rem;display: block !important;"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>模块名称：</span>
                                    <el-form-item prop="moduleName">
                                        <el-input
                                        class="admin_tel"
                                        maxlength="128"
                                        v-model="moduleForm.moduleName"
                                        placeholder="请输入模块名称"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>模板类型：</span>
                                    <el-form-item prop="templateType">
                                        <el-select  @change="templatechang"  class="admin_tel" v-model="moduleForm.templateType" placeholder="请选择模板类型">
                                            <el-option
                                            v-for="item in this.isShowList"
                                            :key="item.key"
                                            :label="item.value"
                                            :value="item.key">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span>&nbsp;&nbsp;排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item prop="designIndex">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.designIndex"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" v-if="moduleTypeshow">
                                    <span><i>*</i>模块类型：</span>
                                    <el-form-item prop="moduleTypeId">
                                        <el-select  class="admin_tel" v-model="moduleForm.moduleTypeId" placeholder="请选择模块类型">
                                            <el-option
                                            v-for="item in this.moduleTypelist"
                                            :key="item.id"
                                            :label="item.moduleTypeName"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 内容模板数据 -->
                            <el-row
                                class="article-add-row"
                                style="height: 44rem;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '10'"
                            >
                                <Editor style="height: 98% !important;" :handleChange="handleChange" />
                            </el-row>
                            <!-- 图片加文字 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '2'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, index) in sublist" :key="index" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents.subTitles[index]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addsublist()" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deletesublist()" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto;">
                                    <span><i>*</i>描述信息：</span>
                                    <el-form-item style="height: auto;">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.description"
                                        type="textarea"
                                        autosize
                                        placeholder="请输入描述信息"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="uploadgo"
                                        action="none"
                                        class="upload-demo"
                                        drag
                                        :before-upload="handleBeforeUploadvideo" 
                                        accept="image/jpg,
                                        image/jpeg,
                                        image/png,
                                        video/mp4,
                                        video/ogg,
                                        video/flv,
                                        video/avi,
                                        video/wmv,
                                        video/rmvb,
                                        video/mov"
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="uploadlist" alt="">
                                        <video :src="uploadlist" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            ref="cascaderItems"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder="areaName"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 导航图 -->
                            <el-row
                                v-for="(item, index) in moduleForm.contents" :key="index" 
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '1'"
                            >
                                <div class="contents-title">
                                    子模块({{index + 1}})
                                </div>
                                <el-button @click="addContentItem" type="success" circle class="special-button">+</el-button>
                                <el-button @click="deleteContentItem" type="danger" circle class="special-button">-</el-button>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, indexs) in list[index].subTitles" :key="indexs" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents[index].subTitles[indexs]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addListItem(index)" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deleteListItem(index)" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="upload"
                                        action="none"
                                        @change="uploadchange(index)"
                                        class="upload-demo"
                                        drag
                                        accept="image/jpg,image/jpeg,image/png" 
                                        :before-upload="handleBeforeUpload" 
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                        <span style="margin-left:1rem;">建议图片像素（3840 * 1520）</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="this.imgURL + this.moduleForm.contents[index].filePath" alt="">
                                        <video :src="this.imgURL + this.moduleForm.contents[index].filePath" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].index"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        maxlength="100"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents[index].linkId"
                                            :options="options"
                                            class="admin_tel input77"
                                            ref="cascaderItem"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="(val) => areaNameChange(val, index)"
                                            :placeholder="areaName"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 子导航 -->
                            <el-row
                                v-for="(item, index) in moduleForm.contents" :key="index" 
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '11'"
                            >
                                <div class="contents-title">
                                    子模块({{index + 1}})
                                </div>
                                <el-button @click="addContentItemye" type="success" circle class="special-button">+</el-button>
                                <el-button @click="deleteContentItemye" type="danger" circle class="special-button">-</el-button>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="upload"
                                        action="none"
                                        @change="uploadchange(index)"
                                        class="upload-demo"
                                        drag
                                        accept="image/jpg,image/jpeg,image/png" 
                                        :before-upload="handleBeforeUpload" 
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="this.imgURL + this.moduleForm.contents[index].filePath" alt="">
                                        <video :src="this.imgURL + this.moduleForm.contents[index].filePath" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].index"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        maxlength="100"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents[index].linkId"
                                            :options="options"
                                            class="admin_tel input77"
                                            ref="cascaderItem"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="(val) => areaNameChange(val, index)"
                                            :placeholder="areaName"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 分页 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '9'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        maxlength="128"
                                        v-model="moduleForm.contents.title"
                                        placeholder="请输入标题" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>每页数量：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.PageSize"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入每页数量" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>共多少页：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.PageRange"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入共多少页" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            ref="cascaderItems"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder=moduleForm.contents.linkName
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 产品 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '6'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, index) in sublist" :key="index" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents.subTitles[index]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addsublist()" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deletesublist()" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto;">
                                    <span><i>*</i>描述信息：</span>
                                    <el-form-item style="height: auto;">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.description"
                                        type="textarea"
                                        autosize
                                        placeholder="请输入描述信息"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="uploadgo"
                                        action="none"
                                        :before-upload="handleBeforeUploadvideo" 
                                        accept="image/jpg,
                                        image/jpeg,
                                        image/png,
                                        video/mp4,
                                        video/ogg,
                                        video/flv,
                                        video/avi,
                                        video/wmv,
                                        video/rmvb,
                                        video/mov,
                                        image/JPG,
                                        image/JPEG,
                                        image/PNG,
                                        video/MP4,
                                        video/OGG,
                                        video/FLV,
                                        video/AVI,
                                        video/WMV,
                                        video/RMVB,
                                        video/MOV"
                                        class="upload-demo"
                                        drag
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                        <span style="margin-left:1rem;">建议图片像素（3840 * 1520）</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="uploadlist" alt="">
                                        <video :src="uploadlist" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            class="admin_tel input77"
                                            ref="cascaderItems"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder="areaName"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.index"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        maxlength="100"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col> -->
                                <div style="width: 100%;">
                                    <div class="contents-title">
                                        添加子模块<span style="margin-left:1rem;font-size: 1rem;color: #ddd;">( 建议增加最少4个子模块达到最佳轮播效果 )</span>
                                    </div>
                                    <el-button @click="addProductsItem()" type="success" circle class="special-button">+</el-button>
                                    <el-button @click="deleteProductsItem()" type="danger" circle class="special-button">-</el-button>
                                </div>
                                <div class="cpdiv" v-for="(item, index) in Productslist" :key="index" style="border-top: 0.1rem solid rgb(75, 75, 75);">
                                    <div class="contents-title">
                                        子模块({{index + 1}})
                                    </div>
                                    <el-col :span="24" :md="24" class="Productslist-cpl">
                                        <span>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                        <el-form-item>
                                            <el-input
                                            class="admin_tel"
                                            v-model="moduleForm.contents.products[index].title"
                                            placeholder="请输入标题"
                                            maxlength="128"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl" style="height: 9rem;">
                                        <span>文件信息：</span>
                                        <el-form-item>
                                            <el-upload
                                            :http-request="Productsupload"
                                            action="none"
                                            @change="uploadchange(index)"
                                            class="upload-demo"
                                            :before-upload="handleBeforeUploadvideo" 
                                            accept="image/jpg,
                                            image/jpeg,
                                            image/png,
                                            image/JPG,
                                            image/JPEG,
                                            image/PNG"
                                            drag
                                            >
                                            <i class="el-icon-upload"></i>
                                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                            </el-upload>
                                            <span style="margin-left:1rem;">建议图片像素（930 * 1094）</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24" style="height: auto !important;" class="Productslist-cpl">
                                        <div class="uploadimg-box">
                                            <img :src="this.imgURL + this.moduleForm.contents.products[index].filePath" alt="">
                                            <video :src="this.imgURL + this.moduleForm.contents.products[index].filePath" />
                                        </div>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl">
                                        <span>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                        <el-form-item>
                                            <el-input
                                            class="admin_tel"
                                            v-model="moduleForm.contents.products[index].index"
                                            oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                            maxlength="100"
                                            placeholder="请输入排序"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24"  class="Productslist-cpl">
                                        <span>分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类：</span>
                                        <el-form-item>
                                            <el-select class="admin_tel" v-model="moduleForm.contents.products[index].LinkType"  @change="(val) => categorychang(val, index)" placeholder="请选择分类">
                                                <el-option
                                                v-for="item in this.categorylinkType"
                                                :key="item.key"
                                                :label="item.value"
                                                :value="item.key">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24"  class="Productslist-cpl" style="height: 45rem !important;" v-show="moduleForm.contents.products[index].LinkType == 2">
                                        <div class="search">
                                            <articleSearch
                                            :reset="reset"
                                            :numTotal="numTotal"
                                            ></articleSearch>
                                        </div>
                                        <articlelist
                                        :header-cell-style="{ 
                                        background:'#000',color:'#fff'}"
                                        :tableHeader="this.articlelogic.opHeaderye()"
                                        :tableData="articleList"
                                        :page="this.articlelogic.searchObj.page"
                                        :row-class-name="tableRowClassName"
                                        >
                                            <template v-slot:btn="slotProps">
                                                <el-button type="warning" @click="designActicle(slotProps.row,index)">
                                                    选择
                                                </el-button>
                                            </template>
                                        </articlelist>
                                        <pager
                                        :page="this.articlelogic.searchObj.page"
                                        :numTotal="numTotal"
                                        style="width: 100%;"
                                        :handleCurrentChange="handleCurrentChange"
                                        >
                                        </pager>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl" v-show="moduleForm.contents.products[index].LinkType == 1">
                                        <span>关联分类：</span>
                                        <el-form-item>
                                            <el-cascader
                                                v-model="moduleForm.contents.products[index].linkId"
                                                :options="options"
                                                ref="cascaderItem"
                                                class="admin_tel input77"
                                                :show-all-levels="false"
                                                :props="this.categorylogic.categoryProps"
                                                @change="(val) => areaNameChangeye(val, index)"
                                                :placeholder="areaName"
                                            >
                                            </el-cascader>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-row>
                        </el-form>
                        <div class="sureBtn">
                            <el-button class="qd" @click="sureAddArticle('AddArticle')" 
                            ></el-button
                            >
                            <el-button class="qx" @click="nosureAddCategory('AddArticle')" 
                            ></el-button
                            >
                        </div>
                    </div>
                </transition>

                <!-- 修改 -->
                <transition name="editright" v-if="editDialogright">
                    <div class="dialog-right">
                        <div class="dialog-right-title">
                            修改模块
                        </div>
                        <el-form ref="EditArticle" :model="moduleForm" :rules="this.categorylogic.designrules" class="medical-from" style="height: 80%;overflow: auto;">
                            <el-row
                                class="article-add-row"
                                style="height: 30rem;margin-left: 1rem;display: block !important;"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>模块名称：</span>
                                    <el-form-item prop="moduleName">
                                        <el-input
                                        class="admin_tel"
                                        maxlength="128"
                                        v-model="moduleForm.moduleName"
                                        placeholder="请输入模块名称"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>模板类型：</span>
                                    <el-form-item prop="templateType">
                                        <el-select :disabled="true"  @change="templatechang"  class="admin_tel" v-model="moduleForm.templateType" placeholder="请选择模板类型">
                                            <el-option
                                            v-for="item in this.isShowList"
                                            :key="item.key"
                                            :label="item.value"
                                            :value="item.key">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span>&nbsp;&nbsp;排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item prop="designIndex">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.designIndex"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" v-if="moduleTypeshow">
                                    <span><i>*</i>模块类型：</span>
                                    <el-form-item prop="moduleTypeId">
                                        <el-select :disabled="true"  class="admin_tel" v-model="moduleForm.moduleTypeId" >
                                            <el-option
                                            v-for="item in this.moduleTypelist"
                                            :key="item.id"
                                            :label="item.moduleTypeName"
                                            :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 内容模板数据 -->
                            <el-row
                                class="article-add-row"
                                style="height: 44rem;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '10'"
                            >
                                <Editor style="height: 98% !important;" :biu="contents" :handleChange="handleChange" />
                            </el-row>
                            <!-- 图片加文字 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '2'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, index) in sublist" :key="index" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents.subTitles[index]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addsublist()" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deletesublist()" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto;">
                                    <span><i>*</i>描述信息：</span>
                                    <el-form-item style="height: auto;">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.description"
                                        type="textarea"
                                        autosize
                                        placeholder="请输入描述信息"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            ref="cascaderItems"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder=moduleForm.contents.linkName
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="uploadgo"
                                        action="none"
                                        class="upload-demo"
                                        drag
                                        :before-upload="handleBeforeUploadvideo" 
                                        accept="image/jpg,
                                        image/jpeg,
                                        image/png,
                                        video/mp4,
                                        video/ogg,
                                        video/flv,
                                        video/avi,
                                        video/wmv,
                                        video/rmvb,
                                        video/mov"
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="uploadlist" alt="">
                                        <video :src="uploadlist" alt="" />
                                    </div>
                                </el-col>
                            
                            </el-row>
                            <!-- 导航图 -->
                            <el-row
                                v-for="(item, index) in moduleForm.contents" :key="index" 
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '1'"
                            >
                                <div class="contents-title">
                                    子模块({{index + 1}})
                                </div>
                                <el-button @click="addContentItem" type="success" circle class="special-button">+</el-button>
                                <el-button @click="deleteContentItem" type="danger" circle class="special-button">-</el-button>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, indexs) in list[index].subTitles" :key="indexs" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents[index].subTitles[indexs]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addListItem(index)" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deleteListItem(index)" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="upload"
                                        action="none"
                                        @change="uploadchange(index)"
                                        class="upload-demo"
                                        accept="image/jpg,image/jpeg,image/png" 
                                        :before-upload="handleBeforeUpload" 
                                        drag
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                        <span style="margin-left:1rem;">建议图片像素（3840 * 1520）</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="this.imgURL + this.moduleForm.contents[index].filePath" alt="">
                                        <video :src="this.imgURL + this.moduleForm.contents[index].filePath" alt="" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].index"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        maxlength="100"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents[index].linkId"
                                            :options="options"
                                            ref="cascaderItem"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="(val) => areaNameChange(val, index)"
                                            :placeholder=moduleForm.contents[index].linkName
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 子导航 -->
                            <el-row
                                v-for="(item, index) in moduleForm.contents" :key="index" 
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '11'"
                            >
                                <div class="contents-title">
                                    子模块({{index + 1}})
                                </div>
                                <el-button @click="addContentItemye" type="success" circle class="special-button">+</el-button>
                                <el-button @click="deleteContentItemye" type="danger" circle class="special-button">-</el-button>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].title"
                                        placeholder="请输入标题" 
                                        maxlength="128"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="upload"
                                        action="none"
                                        @change="uploadchange(index)"
                                        class="upload-demo"
                                        drag
                                        accept="image/jpg,image/jpeg,image/png" 
                                        :before-upload="handleBeforeUpload" 
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="this.imgURL + this.moduleForm.contents[index].filePath" alt="">
                                        <video :src="this.imgURL + this.moduleForm.contents[index].filePath" />
                                    </div>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents[index].index"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        maxlength="100"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents[index].linkId"
                                            :options="options"
                                            class="admin_tel input77"
                                            ref="cascaderItem"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="(val) => areaNameChange(val, index)"
                                            :placeholder="moduleForm.contents[index].linkName"
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 分页 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '9'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        maxlength="128"
                                        v-model="moduleForm.contents.title"
                                        placeholder="请输入标题" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>每页数量：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.pageSize"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入每页数量" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>共多少页：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.pageRange"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入共多少页" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            ref="cascaderItems"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder=moduleForm.contents.linkName
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <!-- 产品 -->
                            <el-row
                                class="article-add-row"
                                style="height: auto;margin-left: 1rem;display: block !important;overflow: hidden;border-top: 0.1rem solid rgb(75, 75, 75);"
                                v-if="this.templatetype == '6'"
                            >
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.title"
                                        maxlength="128"
                                        placeholder="请输入标题" 
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <span><i>*</i>子&nbsp;标&nbsp;题&nbsp;：</span>
                                    <el-form-item style="width: 80%;">
                                        <el-input 
                                        v-for="(item, index) in sublist" :key="index" 
                                        class="admin_tel"
                                        style="margin-top: 1rem;"
                                        v-model="moduleForm.contents.subTitles[index]"
                                        placeholder="请输入子标题" ></el-input>
                                        <el-button @click="addsublist()" type="success" circle class="special-button">+</el-button>
                                        <el-button @click="deletesublist()" type="danger" circle class="special-button">-</el-button>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto;">
                                    <span><i>*</i>描述信息：</span>
                                    <el-form-item style="height: auto;">
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.description"
                                        type="textarea"
                                        autosize
                                        placeholder="请输入描述信息"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" >
                                    <span><i>*</i>文件信息：</span>
                                    <el-form-item>
                                        <el-upload
                                        :http-request="uploadgo"
                                        action="none"
                                        class="upload-demo"
                                        :before-upload="handleBeforeUploadvideo" 
                                        accept="image/jpg,
                                        image/jpeg,
                                        image/png,
                                        video/mp4,
                                        video/ogg,
                                        video/flv,
                                        video/avi,
                                        video/wmv,
                                        video/rmvb,
                                        video/mov,
                                        image/JPG,
                                        image/JPEG,
                                        image/PNG,
                                        video/MP4,
                                        video/OGG,
                                        video/FLV,
                                        video/AVI,
                                        video/WMV,
                                        video/RMVB,
                                        video/MOV"
                                        drag
                                        >
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                        </el-upload>
                                        <span style="margin-left:1rem;">建议图片像素（930 * 1094）</span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" :md="24" style="height: auto !important;">
                                    <div class="uploadimg-box">
                                        <img :src="uploadlist" alt="">
                                        <video :src="uploadlist" alt="" />
                                    </div>
                                </el-col>
                                <!-- <el-col :span="24" :md="24">
                                    <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                    <el-form-item>
                                        <el-input
                                        class="admin_tel"
                                        v-model="moduleForm.contents.index"
                                        maxlength="100"
                                        oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                        placeholder="请输入排序"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </el-col> -->
                                
                                <el-col :span="24" :md="24">
                                    <span><i>*</i>关联分类：</span>
                                    <el-form-item>
                                        <el-cascader
                                            v-model="moduleForm.contents.linkId"
                                            :options="options"
                                            ref="cascaderItems"
                                            class="admin_tel input77"
                                            :show-all-levels="false"
                                            :props="this.categorylogic.categoryProps"
                                            @change="areaNameChangego"
                                            :placeholder=moduleForm.contents.linkName
                                        >
                                        </el-cascader>
                                    </el-form-item>
                                </el-col>
                                <div style="width: 100%;">
                                    <div class="contents-title">
                                        添加子模块<span style="margin-left:1rem;font-size: 1rem;color: #ddd;">( 建议增加最少4个子模块达到最佳轮播效果 )</span>
                                    </div>
                                    <el-button @click="addProductsItem()" type="success" circle class="special-button">+</el-button>
                                    <el-button @click="deleteProductsItem()" type="danger" circle class="special-button">-</el-button>
                                </div>
                                <div class="cpdiv" v-for="(item, index) in Productslist" :key="index" style="border-top: 0.1rem solid rgb(75, 75, 75);">
                                    <div class="contents-title">
                                        子模块({{index + 1}})
                                    </div>
                                    <el-col :span="24" :md="24" class="Productslist-cpl">
                                        <span>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                                        <el-form-item>
                                            <el-input
                                            class="admin_tel"
                                            v-model="moduleForm.contents.products[index].title"
                                            placeholder="请输入标题"
                                            maxlength="128"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl" style="height: 9rem;">
                                        <span>文件信息：</span>
                                        <el-form-item>
                                            <el-upload
                                            :http-request="Productsupload"
                                            action="none"
                                            @change="uploadchange(index)"
                                            class="upload-demo"
                                            drag
                                            :before-upload="handleBeforeUploadvideo" 
                                            accept="image/jpg,
                                            image/jpeg,
                                            image/png,
                                            image/JPG,
                                            image/JPEG,
                                            image/PNG"
                                            >
                                            <i class="el-icon-upload"></i>
                                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                            </el-upload>
                                            <span style="margin-left:1rem;">建议图片像素（930 * 1094）</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24" style="height: auto !important;" class="Productslist-cpl">
                                        <div class="uploadimg-box">
                                            <img :src="this.imgURL + this.moduleForm.contents.products[index].filePath" alt="">
                                            <video :src="this.imgURL + this.moduleForm.contents.products[index].filePath" alt="" />
                                        </div>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl">
                                        <span>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                                        <el-form-item>
                                            <el-input
                                            class="admin_tel"
                                            v-model="moduleForm.contents.products[index].index"
                                            oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                                            maxlength="100"
                                            placeholder="请输入排序"
                                            >
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24"  class="Productslist-cpl">
                                        <span>分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;类：</span>
                                        <el-form-item>
                                            <el-select class="admin_tel" v-model="moduleForm.contents.products[index].linkType"  @change="(val) => categorychang(val, index)" placeholder="请选择分类">
                                                <el-option
                                                v-for="item in this.categorylinkType"
                                                :key="item.key"
                                                :label="item.value"
                                                :placeholder="moduleForm.contents.products[index].linkTypeName"
                                                :value="item.key">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24" :md="24"  class="Productslist-cpl" style="height: 45rem !important;" v-show="moduleForm.contents.products[index].linkType == 2">
                                        <div class="search">
                                            <articleSearch
                                            :reset="reset"
                                            :numTotal="numTotal"
                                            ></articleSearch>
                                        </div>
                                        <articlelist
                                        :header-cell-style="{ 
                                        background:'#000',color:'#fff'}"
                                        :tableHeader="this.articlelogic.opHeaderye()"
                                        :tableData="articleList"
                                        :page="this.articlelogic.searchObj.page"
                                        :row-class-name="tableRowClassName"
                                        >
                                            <template v-slot:btn="slotProps">
                                                <el-button type="warning" @click="designActicle(slotProps.row,index)">
                                                    选择
                                                </el-button>
                                            </template>
                                        </articlelist>
                                        <pager
                                        :page="this.articlelogic.searchObj.page"
                                        :numTotal="numTotal"
                                        style="width: 100%;"
                                        :handleCurrentChange="handleCurrentChange"
                                        >
                                        </pager>
                                    </el-col>
                                    <el-col :span="24" :md="24" class="Productslist-cpl" v-show="moduleForm.contents.products[index].linkType == 1">
                                        <span>关联分类：</span>
                                        <el-form-item>
                                            <el-cascader
                                                v-model="moduleForm.contents.products[index].linkId"
                                                :options="options"
                                                ref="cascaderItem"
                                                class="admin_tel input77"
                                                :show-all-levels="false"
                                                :props="this.categorylogic.categoryProps"
                                                @change="(val) => areaNameChangeye(val, index)"
                                                :placeholder=moduleForm.contents.products[index].linkName
                                            >
                                            </el-cascader>
                                        </el-form-item>
                                    </el-col>
                                </div>
                            </el-row>
                        </el-form>
                        <div class="sureBtn">
                            <el-button class="qd" @click="sureEditArticle('EditArticle')" 
                            ></el-button
                            >
                            <el-button class="qx" @click="nosureEditCategory('EditArticle')" 
                            ></el-button
                            >
                        </div>
                    </div>
                </transition>
            </div>
    </el-drawer>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import categorylist from "@/components/listComponents/treeList";
import Editor from "@/components/articleEditor"
import pager from "@/components/listComponents/pager.vue";
import articlelist from "@/components/listComponents/pagerList";
import articleSearch from "@/components/searchComponents/articleSearch.vue";
export default {
    components: { categorylist,Editor,basecom,top,pager,articlelist,articleSearch },
    data() {
      return {
        // 分类列表数据
        categoryList:[],
        // 关联分类列表
        options:'',
        // 页面设计弹框
        addCategoryDial: false,
        // 当前分类下页面设计列表
        categoryModuleDesignList:'',
        // 页面设计数据
        moduleForm:{
            moduleDataType:1,
            contents:''
        },
        // 多级子标题
        list:[
            {
                subTitles:[]
            }
        ],
        // 产品模块下多级子模块
        Productslist:[''],
        // 图片加文字等 多级子标题
        sublist:[''],
        // 选择模板后的模块类型列表
        moduleTypelist:[],
        // 右侧添加盒子
        dialogright:false,
        // 右侧修改盒子
        editDialogright:false,
        // 上传图片后的显示链接
        uploadlist:'',
        // 多级上传图片的索引
        uploadindex:'',
        // 模板类型列表
        isShowList:[],
        // 选择模板类型后的key值存储
        templatetype:'',
        // 选择模板类型后请求模块列表的值
        moduleTypedata:'',
        // 模块类型选择框的显示隐藏
        moduleTypeshow:false,
        // 当前选择的分类的id存储
        id:'',
        // 修改弹框中富文本回显
        contents:'',
        editye:false,
        articleList:'',
        categorylinkType:[]
      };
    },
    mounted(){
      let aa = {
      }
      this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
          let data = this.resolveData(res.data)
          this.categoryList = data.categoryList
          let nodes = this.categoryList.map((item) => ({
                value: item.id,
                label: item.categoryName,
                leaf: item.hasChildren === false,
            }));
            this.options = nodes;
      })
    },
    methods: {
        search() {
            this.handleCurrentChange();
        },
        designActicle(row,index){
            this.moduleForm.contents.products[index].linkId = row.id
            this.moduleForm.contents.products[index].LinkTypeName =  row.title
            console.log(this.moduleForm)
            this.$message({
                message: '选择标题为' +row.title + '的文章为关联文章成功',
                type: 'success'
            });
        },
        // 产品分类选择
        categorychang(e,index){
            if(this.moduleForm.contents.products[index].LinkType == 1){
                this.handleCurrentChange()
            }else{
            }
        },
        handleCurrentChange: function (page) {
            this.articlelogic.searchObj.page = page;
            this.articlelogic.getArticleList().then((res) => {
            this.articleList = res.articleList;
            this.numTotal = res.total;
            this.articleList.forEach(element => {
                element.isShows = ''
                switch(element.isShow){
                    case true:
                        element.isShows = "已启用";
                    break;
                    case false:
                        element.isShows = "未启用";
                    break;
                }
            });
            });
        },
        // 上传格式二次确认（带视频格式）
        handleBeforeUploadvideo(file){
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            var video = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            const suffix4 = video === 'mp4'
            const suffix5 = video === 'ogg'
            const suffix6 = video === 'flv'
            const suffix7 = video === 'avi'
            const suffix8 = video === 'wmv'
            const suffix9 = video === 'rmvb'
            const suffix10 = video === 'mov'
            const suffix11 = img === 'JPG'
            const suffix12 = img === 'PNG'
            const suffix13 = img === 'JPEG'
            const suffix14 = video === 'MP4'
            const suffix15 = video === 'OGG'
            const suffix16 = video === 'FLV'
            const suffix17 = video === 'AVI'
            const suffix18 = video === 'WMV'
            const suffix19 = video === 'RMVB'
            const suffix20 = video === 'MOV'
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6 && !suffix7 && !suffix8 && !suffix9 
            && !suffix10 && !suffix11 && !suffix12 && !suffix13 && !suffix14 && !suffix15 && !suffix16 && !suffix17 && !suffix18 && !suffix19 && !suffix20) {
                this.$message.error("只能上传图片或视频！");
                return false
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3 || suffix4 || suffix5 || suffix6 || suffix7 || suffix8 || suffix9 || suffix10
        },
        // 上传格式二次确认
        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            const suffix4 = img === 'JPG'
            const suffix5 = img === 'PNG'
            const suffix6 = img === 'JPEG'
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3 && !suffix4 && !suffix5 && !suffix6) {
                this.$message.error("只能上传图片！");
                return false
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3
        },
        // 产品模板 多级子模块上传图片回显
        Productsupload(file){
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.moduleForm.contents.products[this.uploadindex].filePath = res.data.data.relativePath
            });
        },
        // 产品模块 添加子模块
        addProductsItem(){
            var aa = ''
            this.Productslist.push(aa);
            var bb = {
                title:'',
                filePath:'',
                index:'',
                linkId:''
            }
            this.moduleForm.contents.products.push(bb);
        },
        // 产品模块 删除子模块
        deleteProductsItem(){
            this.Productslist = this.Productslist.slice(0, this.Productslist.length-1);
            this.moduleForm.contents.products = this.moduleForm.contents.products.slice(0, this.moduleForm.contents.products.length-1);;
        },
        // 产品模块下多级关联分类change事件
        areaNameChangeye (e,index) {
            this.moduleForm.contents.products[index].linkId = e[e.length - 1]
            this.moduleForm.contents.products[index].LinkTypeName = this.$refs['cascaderItem'][index].cascaderPanelRef.checkedNodes[0].label
        },
        // 关联分类选择change事件
        areaNameChangego(e){
            this.moduleForm.contents.linkId = e[e.length - 1];
            this.moduleForm.contents.linkName = this.$refs['cascaderItems'].cascaderPanelRef.checkedNodes[0].label
        },
        // 文件信息 上传图片回显
        uploadgo(file){
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.uploadlist = ''
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.uploadlist = this.imgURL +  res.data.data.relativePath
                this.moduleForm.contents.filePath = res.data.data.relativePath
            });
        },
        // 添加子标题
        addsublist(){
            this.sublist.push("");
        },
        // 删除子标题
        deletesublist(){
          this.sublist = this.sublist.slice(0, this.sublist.length-1);
        },
        // 模板选择
        templatechang(e){
            this.moduleTypeshow = false
            this.moduleTypelist = []
            this.uploadlist = []
            if(this.editye){
            }else{
                this.moduleForm.moduleTypeId = ''
            }
            this.moduleTypedata = e
            this.list = [
                {
                    subTitles:[]
                }
            ]
            this.sublist = []
            var aa = {
                templateType: this.moduleTypedata
            }
            this.axios.post(this.baseURL + "CategoryModuleDesign/ModuleTypeList",aa, this.headers()).then((res)=>{
                if(res.data.code == 200){
                    this.moduleTypeshow = true
                }
                let data = this.resolveData(res.data)
                this.moduleTypelist = data.moduleTypeList
            })
            switch (e) {
                case 10:
                    this.templatetype = 10
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    this.templatetype = 2
                    this.moduleForm.contents = {
                        subTitles:[]
                    }
                    break;
                case 9:
                    this.templatetype = 9
                    this.moduleForm.contents = {
                    }
                    break;
                case 1:
                    this.templatetype = 1
                    this.moduleForm.contents = [
                            {
                                subTitles:[]  
                            }
                    ]
                    break;
                case 6:
                    this.templatetype = 6
                    this.moduleForm.contents = {
                        subTitles:[],
                        products:[
                            {
                                title:'',
                                filePath:'',
                                index:'',
                                linkId:''
                            }
                        ]
                    }
                    break;
                case 11:
                    this.templatetype = 11
                    this.moduleForm.contents = [
                        {
                            title:'',
                            filePath:'',
                            index:'',
                            linkId:''
                        }
                    ]
                    break;
                case 12:
                    this.templatetype = 12
                    delete this.moduleForm.contents
                    break;
            }
        },
        // 内容模块（富文本）回显
        handleChange: function (editor) {
          let res = editor.getHtml();
          this.moduleForm.contents = res;
        },
        // 删除模块
        deletemodule(id){
            this.$confirm('确定要删除该模块吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var aa = {
                    id:id
                }
                this.axios(
                    {   method: "delete",
                        url: this.baseURL + "CategoryModuleDesign/CategoryModuleDesignDelete",
                        data: aa,
                        headers: this.header(),
                    }
                ).then((res) => {
                this.resolveData(res.data, "delete");
                });
            });
        },
        // 弹出修改
        editmoduleRight(item){
            this.axios.get(this.baseURL + 'CategoryModuleDesign/LinkTypeList',this.headers()) .then((res) => {
                this.categorylinkType = res.data.data.enums
            })
            this.handleCurrentChange()
            this.editye = true
            this.uploadlist = ''
            this.dialogright = false
            this.moduleForm = {
                moduleDataType:1,
                contents:'',
                moduleName:item.moduleName,
                templateType:item.templateType,
                designIndex:item.designIndex,
                moduleTypeId:item.moduleTypeId,
                id:item.id
            }
            this.moduleTypeshow = true
            this.sublist = [],
            this.Productslist = []
            this.list = [
                {
                    subTitles:[]
                }
            ],
            this.templatechang(this.moduleForm.templateType)
            switch (this.moduleForm.templateType) {
                case 10:
                    this.templatetype = 10
                    this.moduleForm.contents = item.moduleData
                    this.contents = item.moduleData
                    break;
                case 9:
                    this.templatetype = 9
                    this.moduleForm.contents = item.moduleData
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    this.templatetype = 2
                    this.moduleForm.contents = item.moduleData
                    for(var i = 0;i<this.moduleForm.contents.subTitles.length;i++){
                        this.sublist.push('')
                    }
                    this.uploadlist = this.imgURL +  this.moduleForm.contents.filePath
                    break;
                case 1:
                    this.templatetype = 1
                    this.moduleForm.contents = item.moduleData
                    for(var i = 0;i<this.moduleForm.contents.length;i++){
                        var bb = {
                            subTitles:[]
                        }
                        this.list.push(bb)
                        for(var j = 0;j<this.moduleForm.contents[i].subTitles.length;j++){
                            this.list[i].subTitles.push('');
                        }
                    }
                    break;
                case 11:
                    this.templatetype = 11
                    this.moduleForm.contents = item.moduleData
                    break;
                case 6:
                    this.templatetype = 6
                    this.moduleForm.contents = item.moduleData
                    for(var i = 0;i < this.moduleForm.contents.products.length;i++){
                        this.Productslist.push('')
                    }
                    for(var i = 0;i < this.moduleForm.contents.subTitles.length;i++){
                        this.sublist.push('')
                    }
                    this.uploadlist = this.imgURL +  this.moduleForm.contents.filePath
                    break;
            }
            setTimeout(()=>{
                this.editDialogright = true
            },200)
        },
        // 子导航模块下添加子模块
        addContentItemye(){
            var aa = {
                title:'',
                filePath:'',
                index:'',
                linkId:'',
            }
            this.moduleForm.contents.push(aa);
        },
        // 子导航模块下删除子模块
        deleteContentItemye(){
            this.moduleForm.contents = this.moduleForm.contents.slice(0, this.moduleForm.contents.length-1);;
        },
        // 导航图模块下多级关联分类change事件
        areaNameChange (e,index) {
            this.moduleForm.contents[index].linkId = e[e.length - 1]
            this.moduleForm.contents[index].linkName = this.$refs['cascaderItem'][index].cascaderPanelRef.checkedNodes[0].label
        },
        // 导航图模块下添加子模块
        addContentItem(){
            var aa = {
                title:'',
                subTitles:[],
                filePath:'',
                index:'',
                linkId:'',
            }
            this.moduleForm.contents.push(aa);
            var bb = {
                subTitles:[]
            }
            this.list.push(bb);
        },
        // 导航图模块下删除子模块
        deleteContentItem(){
            this.moduleForm.contents = this.moduleForm.contents.slice(0, this.moduleForm.contents.length-1);;
        },
        // 导航图 多级子模块上传图片回显
        upload (file) {
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.moduleForm.contents[this.uploadindex].filePath = res.data.data.relativePath
            });
        },
        // 导航图子标题删除
        deleteListItem(index){
            this.list[index].subTitles = this.list[index].subTitles.slice(0, this.list[index].subTitles.length-1);
        },
        // 导航图子标题添加
        addListItem(index) {
            this.list[index].subTitles.push('');
        },
        // 多级上传图片的索引
        uploadchange(e){
            this.uploadindex = e
        },
        // 模块类型 弹出添加
        addmoduleRight(){
            this.editye = false
            this.moduleTypeshow = false
            this.editDialogright = false
            this.templatetype = ''
            this.sublist = [],
            this.Productslist = [''],
            this.list = [
                {
                    subTitles:[]
                }
            ],
            this.axios.get(this.baseURL + 'CategoryModuleDesign/LinkTypeList',this.headers()) .then((res) => {
                this.categorylinkType = res.data.data.enums
                setTimeout(()=>{
                this.moduleForm = {
                    moduleDataType:1,
                    contents:''
                },
                
                this.dialogright = true
            },200)
            })
           
        },
        // 表格隔行变色
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        // 确定修改页面设计
        sureEditArticle(ref){
            this.moduleForm.categoryId = this.id
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    this.axios
                    .post(this.baseURL + "CategoryModuleDesign/CategoryModuleDesignEdit", this.moduleForm, this.headers())
                    .then((res) => {
                        this.resolveData(res.data, "edit");
                    });
                } else {
                    return false;
                }
            });
        },
        // 取消修改 关闭弹窗
        nosureEditCategory(){
            this.$confirm("确认要关闭吗？")
            .then((_) => {
                this.sublist = [],
                this.list = [
                    {
                        subTitles:[]
                    }
                ],
                this.moduleForm = {
                    moduleDataType:1,
                    contents:''
                },
                this.templatetype = ''
                this.moduleTypeshow = false
                this.addCategoryDial = false;
                this.dialogright = false
                this.editDialogright = false
                done();
            })
            .catch((_) => {});
        },
        // 确定添加页面设计
        sureAddArticle(ref) {
            this.moduleForm.categoryId = this.id
            console.log(this.moduleForm)
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    this.axios
                    .post(this.baseURL + "CategoryModuleDesign/CategoryModuleDesignAdd", this.moduleForm, this.headers())
                    .then((res) => {
                        this.resolveData(res.data, "add");
                    });
                } else {
                    return false;
                }
            });
        },
        // 取消添加 关闭弹窗
        nosureAddCategory() {
            this.$confirm("确认要关闭吗？")
            .then((_) => {
                this.sublist = [],
                this.list = [
                    {
                        subTitles:[]
                    }
                ],
                this.moduleForm = {
                    moduleDataType:1,
                    contents:''
                },
                this.templatetype = ''
                this.moduleTypeshow = false
                this.addCategoryDial = false;
                this.dialogright = false
                this.editDialogright = false
                this.list = ['']
                done();
            })
            .catch((_) => {});
        },
        // 页面设计按钮
        addCategory(id) {
            this.addCategoryDial = true;
            this.id = id
            var aa  = {
                categoryId:id
            }
            this.axios.post(this.baseURL + 'CategoryModuleDesign/CategoryModuleDesignList',aa,this.headers()) .then((res) => {
                let data = this.resolveData(res.data)
                this.categoryModuleDesignList = data.categoryModuleDesignList
                this.axios.get(this.baseURL + 'CategoryModuleDesign/TemplateTypeList',this.headers()) .then((res) => {
                    this.isShowList = res.data.data.enums
                })
            })  
        },
    },
};
</script>
  