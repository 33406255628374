<template>
    <div class="body">
        <edit></edit>
    </div>
</template>
  
<script>
import edit from "@/components/articleComponents/edit.vue";

export default {
name: "addUser",
    components: {
        edit,
    },
};
</script>
  