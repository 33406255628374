import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "title", label: "操作内容" },
            { prop: "createDate", label: "发生时间",width:240},
        ];
    },
    getSysList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "SysLog/LogList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
}