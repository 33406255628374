<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <articleSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></articleSearch>
                  </div>
                  <articlelist
                  :header-cell-style="{ 
                  background:'#000',color:'#fff'}"
                  :tableHeader="this.articlelogic.opHeader()"
                  :tableData="articleList"
                  :page="this.articlelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="danger" @click="editModule(slotProps.row)">
                              删除文章
                          </el-button>
                      </template>
                  </articlelist>
                  <pager
                  :page="this.articlelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import articlelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import articleSearch from "@/components/searchComponents/articleSearch.vue";
  export default {
    components: {
        articlelist,
        pager,
        articleSearch,
        top,
        basecom
    },
    data() {
      return {
        articleList: [],
        page: 1,
        numTotal: 0,
      };
    },
    mounted() {
      this.search();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page) {
        this.articlelogic.searchObj.page = page;
        this.articlelogic.getArticleList().then((res) => {
          this.articleList = res.articleList;
          this.numTotal = res.total;
          this.articleList.forEach(element => {
            element.isShows = ''
            switch(element.isShow){
                case true:
                    element.isShows = "已启用";
                break;
                case false:
                    element.isShows = "未启用";
                break;
              }
            });
        });
      },
      sureDeleteArticle() {
        var aa = {
          id:this.mbID
        }
        this.axios(
            {   method: "delete",
                url: this.baseURL + "Article/ArticleDelete",
                data: aa,
                headers: this.header(),
            }
        ).then((res) => {
          this.resolveData(res.data, "delete");
        });
      },
      editModule(row) {
        this.mbID = row.id;
        this.$confirm('确定要删除该文章吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sureDeleteArticle()
        })
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  