<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <Generate></Generate>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import Generate from "@/components/categoryComponents/generate";
export default { 
    components: {
        basecom,
        Generate,
        top
    },
};
</script>
  
  
  