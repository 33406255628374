<template>
    <div class="body">
        <Delete></Delete>
    </div>
</template>
  
<script>
import Delete from "@/components/articleComponents/delete.vue";

export default {
name: "addUser",
    components: {
        Delete,
    },
};
</script>
  