<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
               <i>文章列表&nbsp;·&nbsp;共&nbsp;<span>{{ numTotal }}</span>&nbsp;个</i>
            </div>
          </el-col>
        </div>
        <div class="searchlist">
            <el-col :span="3.5">
                <el-input
                v-model="title"
                placeholder="请输入内容标题"
                @change="setTitle"
                @keyup.enter.native="search"
                >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
            <el-col :span="3.5">
              <el-cascader
                v-model="id"
                :options="options"
                :show-all-levels="false"
                :props="this.categorylogic.categoryProps"
                @change="areaNameChange"
                placeholder="请选择分类"
              >
              </el-cascader>
            </el-col>
            <el-col :span="1.5">
                <div class="search-button" @click="search">查询</div>
            </el-col>
            <el-col :span="1.5">
                <div class="search-button" @click="reset">重置</div>
            </el-col>
            <el-col :span="1.5" v-if="addArticleshow">
                <div class="search-button-add" style="width: 8rem;" @click="addArticle">添加文章</div>
            </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        title: "",
        addArticleshow:false,
        options:[],
        id:''
      };
    },
    props: {
        numTotal: {
          type: Number,
          default: 0,
        },
        search: {
          type: Function,
          default: function () {},
        },
        reset: {
          type: Function,
          default: function () {},
        },
        addArticle: {
            type: Function,
            default: function () {},
        }
    },
    mounted() {
        this.load();
        let href = window.location.href;
        if (href.indexOf("addArticle") > 0) {
            this.addArticleshow = true;
        }
        let aa = {
          }
        this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
          let data = this.resolveData(res.data)
          let nodes = data.categoryList.map((item) => ({
                value: item.id,
                label: item.categoryName,
                leaf: item.hasChildren === false,
            }));
            this.options = nodes;
        })
    },
    methods: {
      load() {
        this.articlelogic.reset();
      },
      areaNameChange (e) {
        this.id = e[e.length - 1];
        this.articlelogic.searchObj.categoryId = this.id
      },
      setTitle() {
        this.articlelogic.searchObj.title = this.title;
        let interval = setInterval(() => {
            if (this.articlelogic.searchObj.title === "") {
                this.title = "";
                clearInterval(interval);
            }
        }, 1000);
      },
    },
  };
  </script>