<template>
    <div class="body">
        <design></design>
    </div>
</template>
<script>
import design from "@/components/categoryComponents/design";
export default { 
    components: {
        design,
    },
};
</script>
