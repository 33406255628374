import { createRouter, createWebHashHistory } from 'vue-router'
import Login from "@/views/Login.vue"
import Index from '@/views/Index.vue'
import Record from '@/views/Record.vue'
import WebConfig from '@/views/WebConfig.vue'

import UserIndex from "@/views/userControl/userIndex"
import AddUser from "@/views/userControl/addUser"
import EditUser from '@/views/userControl/editUser'
import AssignRole from '@/views/userControl/assignRole'
import AssignAgency from '@/views/userControl/assignAgency'

import RoleIndex from '@/views/roleControl/roleIndex'
import AddRole from '@/views/roleControl/addRole'
import EditRole from "@/views/roleControl/editRole"
import AssignFun from "@/views/roleControl/assignFun"

import FuncIndex from '@/views/funcControl/funcIndex'
import AddFunc from '@/views/funcControl/addFunc'
import EditFunc from '@/views/funcControl/editFunc'

import AgencyIndex from '@/views/agencyControl/agencyIndex'
import AddAgency from '@/views/agencyControl/addAgency'
import EditAgency from '@/views/agencyControl/editAgency'

import ModuleIndex from '@/views/moduleControl/moduleIndex'
import AddModule from '@/views/moduleControl/addModule'
import EditModule from '@/views/moduleControl/editModule'
import DeleteModule from '@/views/moduleControl/deleteModule'

import ArticleIndex from '@/views/articleControl/articleIndex'
import AddArticle from '@/views/articleControl/addArticle'
import EditArticle from '@/views/articleControl/editArticle'
import DeleteArticle from '@/views/articleControl/deleteArticle'

import CategoryIndex from '@/views/categoryControl/categoryIndex'
import AddCategory from '@/views/categoryControl/addcategory'
import EditCategory from '@/views/categoryControl/editcategory'
import DeleteCategory from '@/views/categoryControl/deletecategory'
import CategoryDesign from '@/views/categoryControl/categoryDesign'
import GenerateHtml from '@/views/categoryControl/generateHtml'

import FriendlyIndex from '@/views/friendlyControl/friendlyIndex'
import AddFriendly from '@/views/friendlyControl/addFriendly'
import EditFriendly from '@/views/friendlyControl/editFriendly'
import DeleteFriendly from '@/views/friendlyControl/deleteFriendly'

let otherRouter = [
  { path: "/", redirect: '/login.html' },
  { path: "/login.html", name: "登录", component: Login },
  { path: "/index.html", name: "首页", component: Index },
  { path: "/Record.html", name: "操作记录", component: Record },
  { path: "/WebConfig.html", name: "修改网站配置", component: WebConfig },
]

let userRouter = [
  { path: '/userControl/userIndex.html', name: '用户列表', component: UserIndex },
  { path: '/userControl/addUser.html', name: '添加用户', component: AddUser },
  { path: '/userControl/editUser.html', name: '编辑用户', component: EditUser },
  { path: '/userControl/assignRole.html', name: '分配角色', component: AssignRole },
  { path: '/userControl/assignAgency.html', name: '分配机构', component: AssignAgency },
];

let roleRouter = [
  { path: "/roleControl/roleIndex.html", name: "角色列表", component: RoleIndex },
  { path: "/roleControl/addRole.html", name: "添加角色", component: AddRole },
  { path: "/roleControl/editRole.html", name: "修改角色", component: EditRole },
  { path: "/roleControl/assignFun.html", name: "分配功能", component: AssignFun },
];

let funRouter = [
  { path: "/funcControl/funcIndex.html", name: "功能列表", component: FuncIndex },
  { path: "/funcControl/addFunc.html", name: "添加功能", component: AddFunc },
  { path: "/funcControl/editFunc.html", name: "修改功能", component: EditFunc, }
];

let agencyRouter = [
  { path: "/agencyControl/agencyIndex.html", name: "机构列表", component: AgencyIndex },
  { path: "/agencyControl/addAgency.html", name: "添加机构", component: AddAgency },
  { path: "/agencyControl/editAgency.html", name: "修改机构", component: EditAgency, }
];

let moduleRouter = [
  { path: "/moduleControl/moduleIndex.html", name: "模块类型列表", component: ModuleIndex },
  { path: "/moduleControl/addModule.html", name: "添加模块类型", component: AddModule },
  { path: "/moduleControl/editModule.html", name: "修改模块类型", component: EditModule, },
  { path: "/moduleControl/deleteModule.html", name: "删除模块类型", component: DeleteModule, }
];

let articleRouter = [
  { path: "/articleControl/articleIndex.html", name: "文章列表", component: ArticleIndex },
  { path: "/articleControl/addArticle.html", name: "添加文章", component: AddArticle },
  { path: "/articleControl/editArticle.html", name: "修改文章", component: EditArticle, },
  { path: "/articleControl/deleteArticle.html", name: "删除文章", component: DeleteArticle, }
];

let categoryRouter = [
  { path: "/categoryControl/categoryIndex.html", name: "分类列表", component: CategoryIndex },
  { path: "/categoryControl/addcategory.html", name: "添加分类", component: AddCategory },
  { path: "/categoryControl/editcategory.html", name: "修改分类", component: EditCategory, },
  { path: "/categoryControl/deletecategory.html", name: "删除分类", component: DeleteCategory, },
  { path: "/categoryControl/categoryDesign.html", name: "分类页面设计", component: CategoryDesign, },
  { path: "/categoryControl/generateHtml.html", name: "生成页面", component: GenerateHtml, },
];

let FriendlyRouter = [
  { path: "/friendlyControl/friendlyIndex.html", name: "友情链接列表", component: FriendlyIndex },
  { path: "/friendlyControl/addFriendly.html", name: "添加友情链接", component: AddFriendly },
  { path: "/friendlyControl/editFriendly.html", name: "修改友情链接", component: EditFriendly, },
  { path: "/friendlyControl/deleteFriendly.html", name: "删除友情链接", component: DeleteFriendly, }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: []
    .concat(otherRouter)
    .concat(userRouter)
    .concat(roleRouter)
    .concat(funRouter)
    .concat(agencyRouter)
    .concat(moduleRouter)
    .concat(articleRouter)
    .concat(categoryRouter)
    .concat(FriendlyRouter)
});
router.beforeEach((to, from, next) => {
  next()
});
router.afterEach((transition) => {
  let name = transition.name
  let item = router.options.routes.filter((ele) => { return ele.name == name })
  setTitle(item[0].name)
});
function setTitle(title) {
  document.title = title
  var mobile = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(mobile)) {
    var iframe = document.createElement("iframe")
    iframe.style.display = "none"
    var iframeCallback = function () {
      setTimeout(function () {
        iframe.removeEventListener("load", iframeCallback)
        document.body.removeChild(iframe)
      }, 0)
    }
    iframe.addEventListener("load", iframeCallback)
    document.body.appendChild(iframe)
  }
}
export default router
