<template>
<div class="body">
  <div class="top-box-top">
      <top />
  </div>
  <div class="bottom-box">
    <basecom />
    <div class="list">
      <div class="medical-list">
        <roleList
          :row-class-name="tableRowClassName"
          :header-cell-style="{ 
          background:'#000',color:'#fff'}"
          :tableData="this.rolelogic.roleList()"
          :tableHeader="this.rolelogic.opHeader()"
          :lazyLoad="this.rolelogic.getChildRoleList"
        >
        <template v-slot:btn="slotProps">
            <el-button
            type="primary"
            v-if="slotProps.row.rpid != emptyId"
            @click.stop="editrole(slotProps.row)"
            >
            修改角色
            </el-button>
        </template>
        </roleList>
      </div>
    </div>
    <el-drawer
      title="修改角色"
      :model-value="editroleDial"
      :before-close="nosureEditRole"
    >
      <el-form
          ref="editroleForm"
          :model="roleForm"
          :rules="this.rolelogic.rules"
          style="overflow: auto;height: 40vh;margin-top:10vh;;"
        >
        <el-row
          class="add-row"
        >
        <el-col :span="24" :md="20">
            <span><i>*</i>上级角色：</span>
            <el-form-item prop="rpName">
              <el-cascader
              class="admin_tel"
              v-model="roleForm.rpName"
              :options="roleTopNode"
              :show-all-levels="false"
              :props="this.rolelogic.roleProps"
              @change="roleChange"
              :placeholder="rpName"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>角色名称：</span>
            <el-form-item prop="roleName">
              <el-input
              class="admin_tel"
              v-model="roleForm.roleName"
              maxlength="30"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="sureBtn" style="margin-top: 10%;">
        <el-button
          class="qx"
          @click="nosureEditRole('editroleForm')"
          type="info"
          ></el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button
          class="qd"
          @click="sureEditRole('editroleForm')"
          type="primary"
          ></el-button
        >
      </div>
    </el-drawer>
  </div>
</div>
 </template>
<script>
import basecom from "@/components/base";
import top from "@/components/top.vue";
import roleList from "@/components/listComponents/treeList";
export default {
  name: "edit",
  components: { roleList,top,basecom },
  data() {
    return {
      roleTopNode: [],
      childRoleList: [],
      editroleDial: false,
      roleForm: {},
      rpName: "",
      emptyId: this.emptyId,
    };
  },
  mounted() {
    this.rolelogic.roleList().forEach((item) => {
      this.roleTopNode.push({
        value: item.id,
        label: item.roleName,
        leaf: item.hasChildren === false,
      });
    });
  },
  methods: {
  tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
  },
    roleChange(e) {
      this.roleForm.rpid = e[e.length - 1];
    },
    sureEditRole(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let aa = {
            roleName: this.roleForm.roleName,
            id: this.roleForm.id,
            rpid: this.roleForm.rpid,
          };

          this.axios
            .post(this.baseURL + "Role/RoleEdit", aa, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
          this.editroleDial = false;
        }
      });
    },
    nosureEditRole(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editroleDial = false;
          done();
        })
        .catch((_) => {});
    },
    editrole(row) {
      let obj = { ...row };
      this.roleForm = obj;
      this.rpName = this.roleForm.rpName;
      this.editroleDial = true;
      this.resetForm("editroleForm");
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>