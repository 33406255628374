<template>
    <div class="body">
        <add></add>
    </div>
</template>
<script>

import add from "@/components/categoryComponents/add";
export default { 
    components: {
        add,
    },
};
</script>
