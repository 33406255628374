<template>
    <div class="medical-list">
        <div class="search">
            <categorySearch
            :generateCategory="generateCategory"
            ></categorySearch>
        </div>
        <categorylist
            :default-expanded-keys="[]"
            :row-class-name="tableRowClassName"
            :header-cell-style="{ 
            background:'#000',color:'#fff'}"
            :tableData="this.categoryList"
            :tableHeader="this.categorylogic.opHeader()"
            :lazyLoad="this.categorylogic.getChildCategoryList"
        >
            <template v-slot:btn="scope">
                <el-button
                type="success"
                @click.stop="generateCategorys(scope.row)"
                v-if="scope.row.parentId != '00000000-0000-0000-0000-000000000000'"
                >
                生成页面
                </el-button>
            </template>
        </categorylist>
    </div>
  </template>
  <script>
  import categorylist from "@/components/listComponents/treeList";
  import categorySearch from "@/components/searchComponents/categorySearch.vue";
  
  export default {
    components: { categorylist,categorySearch },
    data() {
      return {
        categoryList:'',
        mbID:''
      };
    },
    mounted(){
      let aa = {
      }
      this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
        let data = this.resolveData(res.data)
        this.categoryList = data.categoryList
      })
    },
    methods: {
        generateCategory(){
            this.$confirm('确定要批量生成分类吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.axios.patch(this.baseURL + 'Category/PatchGenerateHTML', '', this.headers()).then(res=>{
                    this.resolveData(res.data, "generate");
                })
            })
        },
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        generateCategorys(row){
            this.mbID = row.id;
            this.$confirm('确定要生成该分类吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
            this.sureDeleteCategory()
            })
        },
       sureDeleteCategory() {
        var aa = {
          id:this.mbID
        }
        this.axios.post(this.baseURL + 'Category/GenerateHTML', aa, this.headers()).then(res => {
            this.resolveData(res.data, "generate");
        })
      },
    },
  };
  </script>
  