<template>
    <div class="body">
        <assignFun></assignFun>
    </div>
  </template>
  <script>
  import assignFun from "@/components/roleComponents/assignFun";
  export default { 
  components: {
    assignFun
  },
  };
  </script>
  
  
  
  
  