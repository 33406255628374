<template>
    <div style="text-align: center;">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[pageSize]"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper"
        :total="numTotal"
      >
      </el-pagination>
    </div>
  </template>
  
  <script>
  export default {
    name: "pager",
    components: {},
    data() {
      return {
        pageSize: 10,
      };
    },
    mounted(){
      let href = window.location.href;
        if (href.indexOf("switchDevice") > 0) {
          this.PageSize = 3
        }else{
          this.PageSize = 10
        }
    },
    props: {
      page: {
        type: Number,
        default: 1,
      },
      numTotal: {
        type: Number,
        default: 0,
      },
      handleCurrentChange: {
        type: Function,
        default: function (page) {},
      },
    },
  };
  </script>
  <style>
  .el-pagination {
    display: flex;
    justify-content: center;
    text-align: center !important;
    color: rgb(255, 255, 255) !important;
    margin-top: 1rem;
  }
  .el-pagination >>> .el-pagination__total {
    color: rgb(255, 255, 255) !important;
  }
  .el-pagination >>> .el-pagination__jump {
    color: rgb(255, 255, 255) !important;
  }
  .el-pagination >>> .el-input {
    width: auto !important;
  }
  </style>
    
  