<template>
    <div class="medical-list">
      <agencylist
        :row-class-name="tableRowClassName"
        :header-cell-style="{
        background:'#000',color:'#fff'}"
         :tableData="this.agencylogic.agencyList()"
        :tableHeader="this.agencylogic.opHeader()"
        :lazyLoad="this.agencylogic.getChildAgencyList"
      >
          <template v-slot:btn="slotProps">
          <el-button
            type="primary"
            @click.stop="addAgency(slotProps.row.id, slotProps.row.functionName)"
          >
              添加子机构
          </el-button>
          </template>
      </agencylist>
    </div>
      <el-dialog
      :model-value="addAgencyDial"
        center
        class="medical-dialogs"
        :before-close="nosureAddAgency"
      >
      <div class="dialog-titile">添加子机构</div>
          <el-form 
            ref="addAgency" 
            :model="agencyFrom" 
            :rules="this.agencylogic.rules"  
            style="overflow: auto;height: 36.2rem;"
            >
            <el-row
              class="add-row"
            >
            <el-col :span="24" :md="20">
              <span><i>*</i>机构名称：</span>
              <el-form-item prop="agencyName">
                <el-input
                  class="admin_tel"
                  v-model="agencyFrom.agencyName"
                  maxlength="30"
                  placeholder="请输入机构名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
              <span><i>*</i>机构编码：</span>
              <el-form-item prop="agencyCode">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.agencyCode"
                maxlength="30"
                placeholder="请输入机构编码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
              <span>&nbsp;<i>*</i>&nbsp;经&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
              <el-form-item prop="longitude">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.longitude"
                maxlength="30"
                placeholder="请输入经度"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
                <span>&nbsp;<i>*</i>&nbsp;纬&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
              <el-form-item prop="latitude">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.latitude"
                maxlength="30"
                placeholder="请输入纬度"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
                <span>&nbsp;<i>*</i>&nbsp;地&nbsp;&nbsp;&nbsp;&nbsp;址：</span>
              <el-form-item prop="address">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.address"
                maxlength="30"
                placeholder="请输入地址"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureAddAgency('addAgency')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureAddAgency('addAgency')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
   
  </template>
  <script>
  import agencylist from "@/components/listComponents/treeList";
  
  export default {
    components: { agencylist },
    data() {
      return {
        addAgencyDial: false,
        agencyFrom: {},
        mbagencyid: "",
        options:[],
      };
    },
    mounted(){
     
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
      },
      nosureAddAgency() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.addAgencyDial = false;
            done();
          })
          .catch((_) => {});
      },
      sureAddAgency(ref) {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            let agencyFrom = this.agencyFrom;
            agencyFrom.apid = this.mbagencyid;
            this.axios
              .post(
                this.baseURL + "Agency/AgencyAdd",
                agencyFrom,
                this.headers()
              )
              .then((res) => {
                this.resolveData(res.data, "add");
              });
          } else {
            return false;
          }
        });
      },
      addAgency(id, name) {
        this.addAgencyDial = true;
        this.agencyFrom = {};
        this.agencyFrom.fpName = name;
        this.mbagencyid = id;
        this.resetForm("addAgency");
      },
      //清除验证提示方法
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
    },
  };
  </script>
  