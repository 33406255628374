<template>
    <div class="body">
         <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div>
                    <el-form ref="AddConfig" :model="ConfigForm"  :rules="this.rules"   class="medical-from" style="height: 68vh;overflow: auto;">
                    <el-row
                        class="add-row"
                        style="justify-content: space-around;height: 60vh;"
                    >
                    <el-col :span="24" :md="24">
                            <span><i>*</i>Logo图标：</span>
                            <el-form-item>
                            <el-upload
                            :http-request="uploadlogoPath"
                            action="none"
                            class="upload-demo"
                            >
                            <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            <img v-if="logoPathimg" :src="this.ConfigForm.logoPath.indexOf('Upload') > -1 ? this.imgURL + this.ConfigForm.logoPath : this.ConfigForm.logoPath" alt="" class="upload-logo" style="margin-left: 1rem;">
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" :md="24">
                            <span style="font-size: 1.3rem;"><i>*</i>二维码图标：</span>
                            <el-form-item>
                            <el-upload
                            :http-request="uploadqrCodePath"
                            action="none"
                            class="upload-demo"
                            >
                            <el-button size="small" type="primary">点击上传</el-button>
                            </el-upload>
                            <img v-if="qrCodePathimg" :src="this.ConfigForm.qrCodePath.indexOf('Upload') > -1 ? this.imgURL + this.ConfigForm.qrCodePath : this.ConfigForm.qrCodePath" alt="" class="upload-logo" style="margin-left: 1rem;">
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" :md="24">
                            <span style="font-size: 1rem;"><i>*</i>LOGO跳转地址：</span>
                            <el-form-item prop="logoLinkPath">
                                <el-input
                                class="admin_tel"
                                style="width: 40rem !important;"
                                v-model="ConfigForm.logoLinkPath"
                                placeholder="请输入LOGO跳转地址"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" :md="24">
                            <span><i>*</i>&nbsp;备&nbsp;案&nbsp;号：</span>
                            <el-form-item prop="icpNum">
                                <el-input
                                class="admin_tel"
                                style="width: 40rem !important;"
                                v-model="ConfigForm.icpNum"
                                placeholder="请输入备案号"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" :md="24">
                            <span><i>*</i>备案信息：</span>
                            <el-form-item prop="networkFiling">
                                <el-input
                                class="admin_tel"
                                style="width: 40rem !important;"
                                v-model="ConfigForm.networkFiling"
                                type="textarea"
                                autosize
                                placeholder="请输入备案信息"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                      
                    </el-row>
                    </el-form>
                    <div class="sureBtn">
                        <el-button class="qd" @click="sureAddConfig('AddConfig')" 
                        ></el-button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import basecom from "@/components/base.vue";
import top from "@/components/top";
export default {
    components: { basecom,top },
    data(){
        return{
            ConfigForm: {networkFiling:''},
            rules: {
                logoPath: [
                    { required: true, message: "请输入Logo地址", trigger: "blur" }
                ],
                logoLinkPath: [
                    { required: true, message: "请输入LOGO跳转地址", trigger: "blur" },
                ],
                icpNum: [
                    { required: true, message: "请输入备案号", trigger: "blur" },
                ],
                qrCodePath: [
                    { required: true, message: "请输入二维码地址", trigger: "blur" }
                ],
                networkFiling: [
                    { required: true, message: "请输入备案信息", trigger: "blur" }
                ],
            },
            logoPathimg:false,
            qrCodePathimg:false
        }
    },
    mounted(){
        this.handleCurrentChange()
    },
    methods:{
        uploadlogoPath(file){
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.ConfigForm.logoPath = res.data.data.relativePath
                this.logoPathimg = true
            });
        },
        uploadqrCodePath(file){
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.ConfigForm.qrCodePath = res.data.data.relativePath
                this.qrCodePathimg = true
            });
        },
        handleCurrentChange() {
            this.axios.get(this.baseURL + 'WebConfig/GetWebConfig',this.headers()) .then((res) => {
                let data = this.resolveData(res.data)
                this.ConfigForm.logoLinkPath = data.logoLinkPath
                this.ConfigForm.icpNum = data.icpNum
                this.ConfigForm.logoPath = data.logoPath
                this.ConfigForm.networkFiling = data.networkFiling
                this.ConfigForm.qrCodePath = data.qrCodePath
                this.ConfigForm.id = data.id
                this.logoPathimg = false
                this.qrCodePathimg = false
                if(this.ConfigForm.logoPath != ''){
                    this.logoPathimg = true
                }
                if(this.ConfigForm.qrCodePath != ''){
                    this.qrCodePathimg = true
                }
            })
        },
        sureAddConfig(){
            this.$confirm('确定要修改网络配置吗', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(this.ConfigForm)
                this.axios.post(this.baseURL + 'WebConfig/WebConfigEdit',this.ConfigForm,this.headers()) .then((res) => {
                    this.resolveData(res.data, "edit");
                })
            })
        }
    }
};
</script>
<style>
@import url('../assets/index.css');
</style>
