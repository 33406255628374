<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
              <i>友情链接列表&nbsp;·&nbsp;共&nbsp;<span>{{ numTotal }}</span>&nbsp;个</i>
            </div>
          </el-col>
        </div>
        <div class="searchlist">
            <el-col :span="3.5">
                <el-input
                v-model="linkName"
                placeholder="请输入友情链接名称"
                @change="setlinkName"
                @keyup.enter.native="search"
                >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
          <el-col :span="1.5">
            <div class="search-button" @click="search">查询</div>
          </el-col>
          <el-col :span="1.5">
            <div class="search-button" @click="reset">重置</div>
          </el-col>
          <el-col :span="1.5" v-if="addFriendlyshow">
            <div class="search-button-add" @click="addFriendly">添加友情链接</div>
          </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        linkName: "",
        addFriendlyshow:false
      };
    },
    props: {
        numTotal: {
          type: Number,
          default: 0,
        },
        search: {
          type: Function,
          default: function () {},
        },
        reset: {
          type: Function,
          default: function () {},
        },
        addFriendly: {
            type: Function,
            default: function () {},
        }
    },
    mounted() {
      this.load();
      let href = window.location.href;
      if (href.indexOf("addFriendly") > 0) {
        this.addFriendlyshow = true;
      }
    },
    methods: {
      load() {
        this.friendlylogic.reset();
      },
      setlinkName() {
        this.friendlylogic.searchObj.linkName = this.linkName;
        let interval = setInterval(() => {
            if (this.friendlylogic.searchObj.linkName === "") {
            this.linkName = "";
            clearInterval(interval);
            }
        }, 1000);
      },
    },
  };
  </script>