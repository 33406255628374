<template>
    <div class="body">
        <edit></edit>
    </div>
  </template>
  <script>
  import edit from "@/components/roleComponents/edit";
  export default { 
  components: {
    edit,
  },
  };
  </script>
  
  
  
  
  
