<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
            <div class="listTitle">
               <i>模块类型列表&nbsp;·&nbsp;共&nbsp;<span>{{ numTotal }}</span>&nbsp;个</i>
            </div>
          </el-col>
        </div>
        <div class="searchlist">
            <el-col :span="3.5">
                <el-input
                v-model="moduleTypeName"
                placeholder="请输入模块类型名称"
                @change="setmoduleTypeName"
                @keyup.enter.native="search"
                >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </el-col>
            <el-col :span="3.5">
                <el-select v-model="templateType"  @change="templateTypeChange" placeholder="请选择模板类型">
                    <el-option
                    v-for="item in this.templateTypeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="1.5">
                <div class="search-button" @click="search">查询</div>
            </el-col>
            <el-col :span="1.5">
                <div class="search-button" @click="reset">重置</div>
            </el-col>
            <el-col :span="1.5" v-if="addModuleshow">
                <div class="search-button-add" style="width: 8rem;" @click="addModule">添加模块类型</div>
            </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        moduleTypeName: "",
        addModuleshow:false,
        templateTypeList:[],
        templateType:''
      };
    },
    props: {
        numTotal: {
          type: Number,
          default: 0,
        },
        search: {
          type: Function,
          default: function () {},
        },
        reset: {
          type: Function,
          default: function () {},
        },
        addModule: {
            type: Function,
            default: function () {},
        }
    },
    mounted() {
        this.load();
        let href = window.location.href;
        if (href.indexOf("addModule") > 0) {
        this.addModuleshow = true;
        }
        this.axios.get(this.baseURL + 'ModuleType/TemplateTypeList',this.headers()) .then((res) => {
            this.templateTypeList = res.data.data.enums
        })
    },
    methods: {
      load() {
        this.modulelogic.reset();
      },
      setmoduleTypeName() {
        this.modulelogic.searchObj.moduleTypeName = this.moduleTypeName;
        let interval = setInterval(() => {
            if (this.modulelogic.searchObj.moduleTypeName === "") {
            this.moduleTypeName = "";
            clearInterval(interval);
            }
        }, 1000);
      },
      templateTypeChange(){
        this.modulelogic.searchObj.templateType = this.templateType;
        let interval = setInterval(() => {
        if (this.modulelogic.searchObj.templateType === "") {
            this.templateType = "";
            clearInterval(interval);
        }
        }, 1000);
    },
    },
  };
  </script>