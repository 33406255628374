<template>
    <div class="body">
         <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <userRecord />
            </div>
        </div>
    </div>
</template>

<script>
import basecom from "@/components/base.vue";
import top from "@/components/top";
import userRecord from "@/components/userRecord";
export default {
    components: { basecom,top,userRecord },
    mounted(){
    }
};
</script>
<style>
@import url('../assets/index.css');
</style>
