<template>
    <div class="medical-list">
      <categorylist
        :default-expanded-keys="[]"
        :row-class-name="tableRowClassName"
        :header-cell-style="{ 
        background:'#000',color:'#fff'}"
        :tableData="this.categoryList"
        :tableHeader="this.categorylogic.opHeader()"
        :lazyLoad="this.categorylogic.getChildCategoryList"
      >
        <template v-slot:btn="scope">
            <el-button
              type="danger"
              v-if="scope.row.id != emptyId"
              @click.stop="deleteCategory(scope.row)"
            >
              删除分类
            </el-button>
          </template>
      </categorylist>
    </div>
  </template>
  <script>
  import categorylist from "@/components/listComponents/treeList";
  
  export default {
    components: { categorylist },
    data() {
      return {
        categoryList:'',
        mbID:''
      };
    },
    mounted(){
      let aa = {
      }
      this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
        let data = this.resolveData(res.data)
        this.categoryList = data.categoryList
      })
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
      },
      deleteCategory(row){
        this.mbID = row.id;
        this.$confirm('确定要删除该分类吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.sureDeleteCategory()
        })
      },
       sureDeleteCategory() {
        var aa = {
          id:this.mbID
        }
        this.axios(
            {   method: "delete",
                url: this.baseURL + "Category/CategoryDelete",
                data: aa,
                headers: this.header(),
            }
        ).then((res) => {
          this.resolveData(res.data, "delete");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 500);
        });
      },
    },
  };
  </script>
  