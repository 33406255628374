<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                    <moduleSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></moduleSearch>
                    </div>
                    <moduleTypelist
                    :header-cell-style="{ 
                    background:'#000',color:'#fff'}"
                    :tableHeader="this.modulelogic.listHeader()"
                    :tableData="moduleTypeList"
                    :page="this.modulelogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    ></moduleTypelist>
                    <pager
                    :page="this.modulelogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import moduleTypelist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import moduleSearch from "@/components/searchComponents/moduleSearch.vue";
export default {
    components: {
        basecom,
        moduleTypelist,
        pager,
        moduleSearch,
        top
    },
    data() {
        return {
            moduleTypeList: [],
            page: 1,
            numTotal: 0,
        };
    },
    mounted() {
        this.search();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.modulelogic.searchObj.page = page;
            this.modulelogic.getModuleList().then((res) => {
                this.moduleTypeList = res.moduleTypeList;
                this.numTotal = res.total;
            });
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
    },
    };
</script>