<template>
    <div class="body">
      <assignAgency></assignAgency>
    </div>
  </template>
  
<script>
import assignAgency from "@/components/userComponents/assignAgency";

export default {
components: {
    assignAgency,
},
};
</script>
  
  