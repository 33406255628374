<template>
    <div>
      <el-row :gutter="10" type="flex" align="middle" style="display:flex; justify-content: space-between;">
        <div>
          <el-col :span="3.5">
          </el-col>
        </div>
        <div class="searchlist">
            <el-col :span="1.5" v-if="categoryShow">
                <div class="search-button-add" style="width: 8rem;" @click="generateCategory">批量生成页面</div>
            </el-col>
        </div>
      </el-row>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        title: "",
        categoryShow:false,
      };
    },
    props: {
        numTotal: {
          type: Number,
          default: 0,
        },
        search: {
          type: Function,
          default: function () {},
        },
        reset: {
          type: Function,
          default: function () {},
        },
        generateCategory: {
            type: Function,
            default: function () {},
        }
    },
    mounted() {
        this.load();
        let href = window.location.href;
        if (href.indexOf("generateHtml") > 0) {
            this.categoryShow = true;
        }
    },
    methods: {
      load() {
        this.articlelogic.reset();
      },
    },
  };
  </script>