import proxy from '../main'
export default {
    TF1: [
        { value: 1, label: "已激活" },
        { value: 2, label: "未激活" },
    ],
    TF2: [
        { value: 1, label: "后台系统" },
        { value: 2, label: "小程序" },
    ],
    TFBtn: [
        { value: 1, label: "功能按钮类型" },
        { value: 2, label: "功能列表类型" }
    ],
    TF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    Show: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    //数据验证 第一项为是否必填  第二项为错误提示信息 第三项为触发方式
    rules: {
        fName: {
            required: true,
            message: "请输入功能名称",
            trigger: "blur",
        },
        fDescription: {
            required: true,
            message: "请输入功能描述",
            trigger: "blur",
        },
        functionAreaType: {
            required: true,
            message: "请选择功能区域",
            trigger: "change",
        },
        fType: {
            required: true,
            message: "请选择功能类型",
            trigger: "change",
        },
        isShow: {
            required: true,
            message: "请选择显示状态",
            trigger: "change",
        },

        fOrder: { required: true, message: "请输入功能排序", trigger: "blur" },

        status: {
            required: true,
            message: "请选择激活状态",
            trigger: "change",
        },

        isMainFunc: {
            required: true,
            message: "请选择是否为系统功能",
            trigger: "change",
        },

        isShow: {
            required: true,
            message: "请选择是否显示",
            trigger: "change",
        },
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "functionName", label: "功能名称", width: "260", align: "left" },
            { prop: "functionType", label: "功能类型" },
            { prop: "fController", label: "控制器" },
            { prop: "fAction", label: "功能方法" },
            { prop: "fOrder", label: "排序值", width: "100" },
            { prop: "activateStatusVal", label: "激活状态" , width: "120"},
            { prop: "isMainFuncVal", label: "系统功能" , width: "120"},
            { prop: "isShowVal", label: "是否显示" , width: "120"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    loadFunList: function () {
        let obj = {
            fAction: "",
            fController: "",
            fOrder: 0,
            fpid: proxy.emptyId,
            functionDescription: "",
            functionName: proxy.SystemShortTitle,
            functionType: "",
            functionTypeCode: 2,
            hasChildren: true,
            id: proxy.emptyId,
            isMainFunc: true,
            isShow: false,
        };
        console.log(obj)
        // setTimeout(() => {
        //     let i = document.querySelector(".el-icon-arrow-right");
        //     i.click();
        // }, 500);
        return [obj];
    },
    getChildFunList: function (row, treeNode, resolve) {
        let childList = [];
        let aa = {
            fpid: row.id,
            fname: "",
        };
        proxy.axios.post(proxy.baseURL + "Function/FunctionList", aa, proxy.headers()).then((res) => {
            let data = proxy.resolveData(res.data);
            childList = data.functionList;
        }).then(() => {
            resolve(childList);
        });
    },


}