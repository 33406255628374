<template>
  <div class="body">
      <div class="top-box-top">
          <top />
      </div>
      <div class="bottom-box">
          <basecom />
          <div class="list">
              <div class="medical-list">
                <div class="search">
                    <userSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></userSearch>
                </div>
                <userlist
                :header-cell-style="{ 
                background:'#000',color:'#fff'}"
                :tableHeader="this.userlogic.opHeader()"
                :tableData="userList"
                :page="this.userlogic.searchObj.page"
                :row-class-name="tableRowClassName"
                >
                    <template v-slot:btn="slotProps">
                        <el-button type="primary" @click="editUser(slotProps.row)">
                            修改用户
                        </el-button>
                    </template>
                </userlist>
                <pager
                :page="this.userlogic.searchObj.page"
                :numTotal="numTotal"
                :handleCurrentChange="handleCurrentChange"
                >
                </pager>
              </div>
          </div>
          <el-drawer
          :model-value="editDrawer"
          title="修改用户"
          :before-close="nosureEditUser">
          <el-form ref="editUser" :model="UserFrom" :rules="this.userlogic.rules" style="overflow: auto;height: 62vh;">
            <el-row
              class="add-row"
              style="justify-content: space-around;height: 60vh;"
            >
              <el-col :span="24" :md="10">
                <span><i>*</i>用户名称：</span>
                <el-form-item prop="userName">
                  <el-input
                    class="admin_tel"
                    style="width: 28.6vh !important;"
                    v-model="UserFrom.userName"
                    maxlength="30"
                    placeholder="请输入用户名称"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="10">
                <span><i>*</i>手&nbsp;&nbsp;机&nbsp;&nbsp;号：</span>
                <el-form-item prop="mobile">
                  <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="UserFrom.mobile"
                  placeholder="请输入手机号"
                  maxlength="11"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="10">
                <span><i>*</i>身份证号：</span>
                <el-form-item prop="idCard">
                  <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  placeholder="请输入身份证号"
                  v-model="UserFrom.idCard"
                  maxlength="18"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="10">
                <span><i>*</i>电子邮箱：</span>
                <el-form-item prop="email">
                  <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  placeholder="请输入电子邮箱"
                  v-model="UserFrom.email"
                  maxlength="30"
                  type="email"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="10">
                <span class="electrode"><i>*</i>短信接收状态：</span>
                <el-form-item prop="mobileReceiveStatus">
                  <el-select
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="UserFrom.mobileReceiveStatus"
                  placeholder="请选择"
                  >
                    <el-option
                      v-for="item in this.userlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24" :md="10">
                <span class="electrode"><i>*</i>邮箱接收状态：</span>
                <el-form-item prop="emailReceiveStatus">
                  <el-select
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="UserFrom.emailReceiveStatus"
                  placeholder="请选择"
                  >
                    <el-option
                      v-for="item in this.userlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="sureBtn">
            <el-button class="qx" @click="nosureEditUser('editUser')" type="info"
              ></el-button
            >
            
            <el-button class="qd" @click="sureEditUser('editUser')" type="primary"
              ></el-button
            >
          </div>
          </el-drawer>
      </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import userlist from "@/components/listComponents/pagerList";
import top from "@/components/top.vue";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  components: {
    userlist,
    pager,
    userSearch,
    top,
    basecom
  },
  data() {
    return {
      UserFrom: {},
      userList: [],
      page: 1,
      numTotal: 0,
      TF: [
        { value: false, label: "否" },
        { value: true, label: "是" },
      ],
      editDrawer:false,
    };
  },
  mounted() {
    this.search();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
    },
    handleCurrentChange: function (page, userName, email, mobile) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    sureEditUser(ref) {
      let aa = this.UserFrom;
      aa.id = this.mbID;
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(this.baseURL + "User/UserEdit", aa, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
        } else {
          return false;
        }
      });
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    nosureEditUser() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editDrawer = false
          done();
        })
        .catch((_) => {});
    },
    editUser(row) {
      let obj = { ...row };
      this.UserFrom = obj;
      this.mbID = row.id;
      this.editDrawer = true
      this.resetForm("editUser");
    },
    reset() {
      window.location.reload()
    },
    search() {
      this.handleCurrentChange();
    },
  },
};
</script>
