<template>
    <div class="body">
      <div class="top-box-top">
          <top />
      </div>
      <div class="bottom-box">
        <basecom />
        <div class="list">
          <div class="medical-list">
              <div class="search">
                <articleSearch
                :search="search"
                :reset="reset"
                :numTotal="numTotal"
                :addArticle="addArticle"
                ></articleSearch>
              </div>
              <articlelist
              :header-cell-style="{ 
              background:'#000',color:'#fff'}"
              :tableHeader="this.articlelogic.listHeader()"
              :tableData="articleList"
              :page="this.articlelogic.searchObj.page"
              :row-class-name="tableRowClassName"
              ></articlelist>
              <pager
              :page="this.articlelogic.searchObj.page"
              :numTotal="numTotal"
              :handleCurrentChange="handleCurrentChange"
              >
              </pager>
          </div>
        </div>
        <el-drawer
          :model-value="aadDrawer"
          title="添加文章"
          :before-close="handleClose">
          <el-form ref="AddArticle" :model="articleForm" :rules="this.articlelogic.designrules" class="medical-from" style="height: 68vh;overflow: auto;">
          <el-row
            class="article-add-row"
            style="justify-content: none !important;height: 60vh;"
          >
            <el-col :span="24" :md="24">
              <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
              <el-form-item prop="title">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="articleForm.title"
                  maxlength="128"
                  placeholder="请输入标题"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24" style="height: auto !important;">
              <span>&nbsp;子&nbsp;&nbsp;标&nbsp;&nbsp;题：</span>
              <el-form-item prop="subTitles" style="width: 80%;">
                <el-input 
                  v-for="(item, index) in list" :key="index" 
                  class="admin_tel"
                  style="width: 75.6vh !important;margin-top: 1rem;"
                  v-model="articleForm.subTitles[index]"
                  placeholder="请输入子标题" ></el-input>
                  <el-button @click="addListItem" type="success" circle class="special-button">+</el-button>
                  <el-button @click="deleteListItem" type="danger" circle class="special-button">-</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>&nbsp;关&nbsp;键&nbsp;词：</span>
              <el-form-item prop="keyWords">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="articleForm.keyWords"
                  placeholder="请输入关键词"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24" style="height: auto;">
              <span><i>*</i>描&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;述：</span>
              <el-form-item prop="description" style="height: auto;">
                <el-input
                  class="admin_tel"
                  type="textarea"
                  autosize
                  style="width: 75.6vh !important;"
                  v-model="articleForm.description"
                  placeholder="请输入描述"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>作&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;者：</span>
              <el-form-item prop="author">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="articleForm.author"
                  maxlength="20"
                  placeholder="请输入作者"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span>&nbsp;&nbsp;排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
              <el-form-item prop="articleIndex">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="articleForm.articleIndex"
                  oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                  maxlength="20"
                  placeholder="请输入排序"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>关联分类：</span>
              <el-form-item prop="categoryId">
                <el-cascader
                  v-model="articleForm.categoryId"
                  :options="options"
                  style="width: 75.6vh !important;"
                  class="admin_tel input77"
                  :show-all-levels="false"
                  @change="areaNameChange"
                  :props="this.categorylogic.categoryProps"
                  placeholder="请选择关联分类"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>启用状态：</span>
              <el-form-item prop="isShow">
                <el-select  class="admin_tel" style="width: 28.6vh !important;" v-model="articleForm.isShow" placeholder="请选择启用状态">
                    <el-option
                    v-for="item in this.articlelogic.Show"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24" >
                <span><i>*&nbsp;</i>缩&nbsp;略&nbsp;图：</span>
                <el-form-item>
                    <el-upload
                    :http-request="upload"
                    action="none"
                    class="upload-demo"
                    accept="image/jpg,image/jpeg,image/png" 
                    :before-upload="handleBeforeUpload" 
                    >
                    <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </el-form-item>
            </el-col>
            <el-col :span="24" :md="24" style="height: auto !important;">
                <div v-for="(item,index) in articleForm.images " class="uploadimg-box" style="display: flex;align-items: center;">
                    <img :src="this.imgURL + item" alt="" style="width: 20rem;">
                    <div class="imgdelete" @click="imgdelete(index)"><el-button type="danger" round>删除</el-button></div>
                </div>
            </el-col>
            <Editor :handleChange="handleChange" />
          </el-row>
          </el-form>
          <div class="sureBtn">
            <el-button class="qd" @click="sureAddArticle('AddArticle')" 
              ></el-button
            >
            <el-button class="qx" @click="handleClose('AddArticle')" 
              ></el-button
            >
          </div>
        </el-drawer>
      </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import articlelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import articleSearch from "@/components/searchComponents/articleSearch.vue";
  import Editor from "@/components/articleEditor"
  export default {
        components: {
            articlelist,
            pager,
            articleSearch,
            top,
            basecom,
            Editor,
        },
        name: "add",
        data() {
          return {
            articleList: [],
            aadDrawer:false,
            page: 1,
            numTotal: 0,
            templateTypeList:[],
            templateType:'',
            content:'',
            articleForm:{
              subTitles:[],
              images:[]
            },
            subTitles:'',
            list: [""],
            options:'',
          }
        },
      mounted() {  
        this.search();
      },
      methods: {
        handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3) {
                this.$message.error("只能上传图片！");
                return false
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3
        },
        imgdelete(index){
          this.$confirm('确定要取消添加该图片吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.articleForm.images.splice(index, 1)
          })
        },
        // 上传文件
        upload (file) {
            let fileobj = file.file;
            var formdata = new FormData();
            formdata.append("FileInfo", fileobj);
            var aa = 1
            this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
                this.articleForm.images.push(res.data.data.relativePath)
            });
        },
        areaNameChange (e) {
          this.articleForm.categoryId = e[e.length - 1];
        },
        addListItem() {
          this.list.push("");
        },
        deleteListItem(){
          this.list = this.list.slice(0, this.list.length-1);
        },
        handleChange: function (editor) {
          let res = editor.getHtml();
          this.articleForm.contents = res;
        },
        tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
        },
        handleCurrentChange: function (page = 1) {
            this.articlelogic.searchObj.page = page;
            this.articlelogic.getArticleList().then((res) => {
                this.articleList = res.articleList;
                this.numTotal = res.total;
                this.articleList.forEach(element => {
                  element.isShows = ''
                  switch(element.isShow){
                      case true:
                          element.isShows = "已启用";
                      break;
                      case false:
                          element.isShows = "未启用";
                      break;
                  }
                });
            });
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.aadDrawer = false
              this.list = [""]
              done();
            })
            .catch(_ => {});
        },
        addArticle(){
          this.aadDrawer = true
          let aa = {
          }
          this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
              let data = this.resolveData(res.data)
              let nodes = data.categoryList.map((item) => ({
                    value: item.id,
                    label: item.categoryName,
                    leaf: item.hasChildren === false,
                }));
                this.options = nodes;
          })
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
        sureAddArticle(ref) {
          console.log(this.articleForm)
          this.$refs[ref].validate((valid) => {
            if (valid) {
            this.articleForm.contents = this.articleForm.contents.replace('\\');
                this.axios
                .post(this.baseURL + "Article/ArticleAdd", this.articleForm, this.headers())
                .then((res) => {
                    this.resolveData(res.data, "add");
                    // if(res.data.code != 200){
                    // }else{
                    //   this.aadDrawer = false
                    // }
                });
            } else {
                return false;
            }
          });
        },
      },
  };
  </script>
     
     
     