<template>
    <div class="body">
        <edit></edit>
    </div>
</template>
<script>
import edit from "@/components/funcComponents/edit";
export default { 
    components: {
        edit,
    },
};
</script>
  
  
  
  
  