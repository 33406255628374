<template>
    <div class="body">
      <div class="top-box-top">
          <top />
      </div>
      <div class="bottom-box">
        <basecom />
        <div class="list">
          <div class="medical-list">
              <div class="search">
                <friendlySearch
                  :search="search"
                  :reset="reset"
                  :numTotal="numTotal"
                  :addFriendly="addFriendly"
                  ></friendlySearch>
              </div>
              <friendlyLinklist
              :header-cell-style="{ 
              background:'#000',color:'#fff'}"
              :tableHeader="this.friendlylogic.listHeader()"
              :tableData="friendlyLinkList"
              :page="this.friendlylogic.searchObj.page"
              :row-class-name="tableRowClassName"
              ></friendlyLinklist>
              <pager
              :page="this.friendlylogic.searchObj.page"
              :numTotal="numTotal"
              :handleCurrentChange="handleCurrentChange"
              >
              </pager>
          </div>
        </div>
        <el-drawer
          :model-value="aadDrawer"
          title="添加友情链接"
          :before-close="handleClose">
          <el-form ref="AddFriendly" :model="FriendlyFrom" :rules="this.friendlylogic.rules" class="medical-from" style="height: 68vh;overflow: auto;">
          <el-row
            class="add-row"
            style="justify-content: space-around;height: 60vh;"
          >
            <el-col :span="24" :md="10">
              <span style="font-size: 1.2rem;"><i>*</i>友情链接名称：</span>
              <el-form-item prop="linkName">
                <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="FriendlyFrom.linkName"
                  maxlength="20"
                  placeholder="请输入友情链接名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <span style="font-size: 1.2rem;"><i>*</i>友情链接地址：</span>
              <el-form-item prop="linkPath">
                <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="FriendlyFrom.linkPath"
                  placeholder="请输入友情链接地址"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
              <el-form-item prop="linkIndex">
                <el-input
                  class="admin_tel"
                  style="width: 28.6vh !important;"
                  v-model="FriendlyFrom.linkIndex"
                  maxlength="20"
                  oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                  placeholder="请输入排序"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <span><i>*</i>是否显示：</span>
              <el-form-item prop="isShow">
                  <el-select
                    style="width: 28.6vh !important;"
                    v-model="FriendlyFrom.isShow"
                    placeholder="请选择"
                    class="admin_tel"
                  >
                    <el-option
                      v-for="item in this.friendlylogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
          <div class="sureBtn">
            <el-button class="qd" @click="sureAddFriendly('AddFriendly')" 
              ></el-button
            >
            <el-button class="qx" @click="nosureAddFriendly('AddFriendly')" 
              ></el-button
            >
          </div>
        </el-drawer>
      </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import friendlyLinklist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import friendlySearch from "@/components/searchComponents/friendlySearch.vue";
  export default {
      components: {
        friendlyLinklist,
        pager,
        friendlySearch,
        top,
        basecom
      },
      name: "add",
      data() {
      return {
          FriendlyFrom: {
          },
          friendlyLinkList: [],
          aadDrawer:false,
          page: 1,
          numTotal: 0,
      };
      },
      mounted() {  
        this.search();
      },
      methods: {
          tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
          },
          handleCurrentChange: function (page = 1) {
              this.friendlylogic.searchObj.page = page;
              this.friendlylogic.getFriendlyList().then((res) => {
                  this.friendlyLinkList = res.friendlyLinkList;
                  this.numTotal = res.total;
              });
          },
          handleClose(done) {
            this.$confirm('确认关闭？')
              .then(_ => {
                this.aadDrawer = false
                done();
              })
              .catch(_ => {});
          },
          addFriendly(){
              this.aadDrawer = true
          },
          reset() {
              window.location.reload()
          },
          search() {
              this.handleCurrentChange();
          },
          sureAddFriendly(ref) {
              this.$refs[ref].validate((valid) => {
                  if (valid) {
                      this.axios
                      .post(this.baseURL + "FriendlyLinks/FriendlyLinksAdd", this.FriendlyFrom, this.headers())
                      .then((res) => {
                          this.resolveData(res.data, "add");
                      });
                  } else {
                      return false;
                  }
              });
          },
          nosureAddFriendly() {
              this.$confirm("确认要关闭吗？")
              .then((_) => {
                this.aadDrawer = false
                done();
              })
              .catch((_) => {});
          },
      },
  };
  </script>
     
     
     