<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <friendlySearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></friendlySearch>
                  </div>
                  <friendlyLinklist
                  :header-cell-style="{ 
                  background:'#000',color:'#fff'}"
                  :tableHeader="this.friendlylogic.opHeader()"
                  :tableData="friendlyLinkList"
                  :page="this.friendlylogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="primary" @click="editFriendly(slotProps.row)">
                              修改友情链接
                          </el-button>
                      </template>
                  </friendlyLinklist>
                  <pager
                  :page="this.friendlylogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
            <el-drawer
            :model-value="editDrawer"
            title="修改友情链接"
            :before-close="nosureEditFriendly">
            <el-form ref="editFriendly" :model="FriendlyFrom" :rules="this.friendlylogic.rules" style="overflow: auto;height: 62vh;">
              <el-row
                class="add-row"
                style="justify-content: space-around;height: 60vh;"
              >
                <el-col :span="24" :md="10">
                    <span><i>*</i>友情链接名称：</span>
                    <el-form-item prop="linkName">
                        <el-input
                        class="admin_tel"
                        style="width: 28.6vh !important;"
                        v-model="FriendlyFrom.linkName"
                        maxlength="20"
                        placeholder="请输入友情链接名称"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" :md="10">
                    <span style="font-size: 1.2rem;"><i>*</i>友情链接地址：</span>
                    <el-form-item prop="linkPath">
                        <el-input
                        class="admin_tel"
                        style="width: 28.6vh !important;"
                        v-model="FriendlyFrom.linkPath"
                        placeholder="请输入友情链接地址"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" :md="10">
              <span><i>*</i>排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
              <el-form-item prop="linkIndex">
                <el-input
                  class="admin_tel"
                  oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                  style="width: 28.6vh !important;"
                  v-model="FriendlyFrom.linkIndex"
                  maxlength="18"
                  placeholder="请输入排序"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <span><i>*</i>是否显示：</span>
              <el-form-item prop="isShow">
                  <el-select
                    style="width: 28.6vh !important;"
                    v-model="FriendlyFrom.isShow"
                    placeholder="请选择"
                    class="admin_tel"
                  >
                    <el-option
                      v-for="item in this.friendlylogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
              </el-form-item>
            </el-col>
              </el-row>
            </el-form>
            <div class="sureBtn">
              <el-button class="qx" @click="nosureEditFriendly('editFriendly')" type="info"
                ></el-button
              >
              
              <el-button class="qd" @click="sureEditFriendly('editFriendly')" type="primary"
                ></el-button
              >
            </div>
            </el-drawer>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import friendlyLinklist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import friendlySearch from "@/components/searchComponents/friendlySearch.vue";
  export default {
    components: {
        friendlyLinklist,
        pager,
        friendlySearch,
        top,
        basecom
    },
    data() {
      return {
        FriendlyFrom: {},
        friendlyLinkList: [],
        page: 1,
        numTotal: 0,
        editDrawer:false,
      };
    },
    mounted() {
      this.search();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.friendlylogic.searchObj.page = page;
        this.friendlylogic.getFriendlyList().then((res) => {
          this.friendlyLinkList = res.friendlyLinkList;
          this.numTotal = res.total;
        });
      },
      sureEditFriendly(ref) {
        let aa = this.FriendlyFrom;
        aa.id = this.mbID;
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.axios
              .post(this.baseURL + "FriendlyLinks/FriendlyLinksEdit", aa, this.headers())
              .then((res) => {
                this.resolveData(res.data, "edit");
              });
          } else {
            return false;
          }
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      nosureEditFriendly() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editDrawer = false
            done();
          })
          .catch((_) => {});
      },
      editFriendly(row) {
        let obj = { ...row };
        this.FriendlyFrom = obj;
        this.mbID = row.id;
        this.editDrawer = true
        this.resetForm("editFriendly");
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  