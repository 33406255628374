import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        // moduleTypeName: "",
        // templateType: 0
    },
    rules: {
        moduleTemplate: [
            { required: true, message: "请输入模板内容", trigger: "blur" },
        ],
        moduleTypeName: [
            { required: true, message: "请输入模块类型名称", trigger: "blur" },
        ],
        templateType: [
            { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        moduleOrder: [
            { required: true, message: "请输入模板排序", trigger: "blur" },
        ],
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "moduleTypeName", label: "模块类型名称",width:260 }, 
            { prop: "templateTypeName", label: "模板类型名称",width:260},
            { prop: "moduleOrder", label: "模块排序",width:180 },
            { prop: "createUser", label: "创建用户",width:280},
            { prop: "createDate", label: "创建时间"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:150 });
        return header;
    },
    getModuleList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "ModuleType/ModuleTypeList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = { page: 1};
    }
}