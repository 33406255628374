<template>
  <router-view/>
</template>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: rgb(0, 0, 0);
  overflow: hidden;
}
.body{
  height: 100% !important;
}
.el-loading-parent--relative{
  position: none !important;
  height: 100%;
}
.el-message > .el-icon  > svg{
  height:2rem !important;
  width: 4rem !important;
}
.el-message{
  height: 3rem !important;
  width: 15rem !important;
}
</style>
