<template>
  <div class="body">
    <div class="top-box-top">
        <top />
    </div>
    <div class="bottom-box">
      <basecom />
      <div class="list">
        <div class="medical-list">
            <funListTable
                :row-class-name="tableRowClassName"
                :header-cell-style="{ 
                background:'#000',color:'#fff'}"
                :tableData="this.funlogic.loadFunList()"
                :tableHeader="this.funlogic.opHeader()"
                :lazyLoad="this.funlogic.getChildFunList"
            >
                <template v-slot:btn="scope">
                  <el-button
                    type="primary"
                    v-if="scope.row.id != emptyId"
                    @click.stop="editFunc(scope.row)"
                  >
                    编辑
                  </el-button>
                </template>
            </funListTable>
        </div>
      </div>
      <el-drawer
        :model-value="editFunDial"
        title="修改功能信息"
        :before-close="noEditFun"
      >
        <el-form
          ref="editFuncForm"
          :model="funFrom"
          :rules="this.funlogic.rules"
          style="overflow: auto;height: 58vh;margin-top: 3vh;"
        >
        <el-row
          class="add-row"
        >
          <el-col :span="24" :md="20">
            <span><i>*</i>功能名称：</span>
            <el-form-item prop="fName">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fName"
              maxlength="30"
              placeholder="请输入功能名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20"  v-if="funFrom.fpid != emptyId">
            <span><i>*</i>上级功能：</span>
            <el-form-item prop="fpName">
              <el-cascader
              v-model="funFrom.fpName"
              class="admin_tel input77"
              :show-all-levels="false"
              :props="parentListProps"
              @change="fpNameChange"
              :placeholder="fpName"
              >
              </el-cascader>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24" :md="20">
            <span><i>*</i>功能区域：</span>
            <el-form-item prop="functionAreaType">
              <el-select
              v-model="funFrom.functionAreaType"
              class="admin_tel input77"
              >
                <el-option
                v-for="item in this.funlogic.TF2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" :md="20">
            <span class="electrode"><i>*</i>&nbsp;&nbsp;功能控制器：</span>
            <el-form-item prop="fController">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fController"
              placeholder="请输入功能控制器"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>激活状态：</span>
            <el-form-item prop="status">
              <el-select
              v-model="funFrom.status"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                v-for="item in this.funlogic.TF1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能方法：</span>
            <el-form-item prop="fAction">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fAction"
              placeholder="请输入功能方法"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能类型：</span>
            <el-form-item prop="fType">
              <el-select
                v-model="funFrom.fType"
                placeholder="请选择"
                class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.TFBtn"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能图标：</span>
            <el-form-item prop="fIcon">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fIcon"
              maxlength="20"
              placeholder="功能图标可为空"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能排序：</span>
            <el-form-item prop="fOrder">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fOrder"
              maxlength="30"
              type="Number"
              placeholder="请输入功能排序值"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>系统功能：</span>
            <el-form-item prop="isMainFunc">
              <el-select
              v-model="funFrom.isMainFunc"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.TF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>是否显示：</span>
            <el-form-item prop="isShow">
              <el-select
              v-model="funFrom.isShow"
              placeholder="请选择"
              class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.funlogic.Show"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>功能描述：</span>
            <el-form-item prop="fDescription">
              <el-input
              class="admin_tel input77"
              v-model="funFrom.fDescription"
              maxlength="100"
              placeholder="请输入功能描述"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        </el-form>
        <div class="sureBtn" style=" margin-top: 5vh !important;">
          <el-button class="qx" @click="noEditFun('editFuncForm')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="okEditFun('editFuncForm')" type="primary"
            ></el-button
          >
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import proxy from "@/main";
import funListTable from "@/components/listComponents/treeList";
import basecom from "@/components/base";
import top from "@/components/top.vue";
export default {
  name: "addFunc",
  components: { funListTable,top,basecom },
  data() {
    return {
      fpName: "",
      emptyId: this.emptyId,
      editFunDial: false,
      funFrom: {},
      parentListProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.root == true) {
            node.value = proxy.emptyId;
          }
          let aa = {
            fpid: node.value,
            fname: "",
          };

          proxy.axios
            .post(proxy.baseURL + "Function/FunctionList", aa, proxy.headers())
            .then((res) => {
              let data = proxy.resolveData(res.data);

              let childFunList = data.functionList;
              let nodes = childFunList.map((item) => ({
                value: item.id,
                label: item.functionName,
                leaf: item.hasChildren === false,
              }));
              resolve(nodes);
            });
        },
      },
    };
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
    },
    fpNameChange(e) {
      this.funFrom.fpid = e[e.length - 1];
    },
    noEditFun() {
      //弹窗关闭
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editFunDial = false;
          done();
        })
        .catch((_) => {});
    },
    okEditFun(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "Function/FunctionEdit",
              this.funFrom,
              this.headers()
            )
            .then((res) => {
              if(res.data.data.flg){
                this.resolveData(res.data, "edit");
                this.editFunDial = false;
              }else{
                this.resolveData(res.data, "edit");
              }
            });
        }
      });
    },

    editFunc(row) {
      //弹窗开启
      this.editFunDial = true;
      let obj = { ...row };
      this.funFrom = {
        id: row.id,
        fpid: row.fpid,
        fName: row.functionName,
        fDescription: row.functionDescription,
        fType: row.functionTypeCode,
        fController: row.fController,
        fAction: row.fAction,
        fIcon: row.fIcon,
        fOrder: row.fOrder,
        status: row.activateStatus,
        isMainFunc: row.isMainFunc,
        isShow: row.isShow,
        // functionAreaType: row.functionAreaType,
      };
      this.fpName = row.fpName;

      this.resetForm("editFuncForm");
    },

    //清除验证提示方法
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>
  