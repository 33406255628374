<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                    <articleSearch
                    :search="search"
                    :reset="reset"
                    :numTotal="numTotal"
                    ></articleSearch>
                  </div>
                  <articlelist
                  :header-cell-style="{ 
                  background:'#000',color:'#fff'}"
                  :tableHeader="this.articlelogic.opHeader()"
                  :tableData="articleList"
                  :page="this.articlelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="warning" @click="editArticle(slotProps.row)">
                              修改文章
                          </el-button>
                      </template>
                  </articlelist>
                  <pager
                  :page="this.articlelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
            <el-drawer
            v-if="editDrawer"
            :model-value="editDrawer"
            title="修改文章"
            :before-close="handleClose">
            <el-form ref="editArticle" :model="ArticleForm" :rules="this.articlelogic.rules" style="overflow: auto;height: 62vh;">
                <el-row
                    class="article-add-row"
                    style="justify-content: none !important;height: 60vh;"
                    >
                    <el-col :span="24" :md="24">
                    <span><i>*</i>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;题：</span>
                    <el-form-item prop="title">
                        <el-input
                        class="admin_tel"
                        style="width: 75.6vh !important;"
                        v-model="ArticleForm.title"
                        maxlength="128"
                        placeholder="请输入标题"
                        >
                        </el-input>
                    </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24" style="height: auto !important;">
                      <span>&nbsp;子&nbsp;&nbsp;标&nbsp;&nbsp;题：</span>
                      <el-form-item prop="subTitles" style="width: 80%;">
                          <el-input 
                          v-for="(item, index) in list" :key="index" 
                          class="admin_tel"
                          style="width: 75.6vh !important;margin-top: 1rem;"
                          v-model="ArticleForm.subTitles[index]"
                          placeholder="请输入子标题" ></el-input>
                          <el-button @click="addListItem" type="success" circle class="special-button">+</el-button>
                          <el-button @click="deleteListItem" type="danger" circle class="special-button">-</el-button>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24">
                      <span><i>*</i>&nbsp;关&nbsp;键&nbsp;词：</span>
                      <el-form-item prop="w">
                        <el-input
                          class="admin_tel"
                          style="width: 75.6vh !important;"
                          v-model="ArticleForm.keyWords"
                          placeholder="请输入关键词"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24" style="height: auto;">
                      <span><i>*</i>描&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;述：</span>
                      <el-form-item prop="description" style="height: auto;">
                        <el-input
                          class="admin_tel"
                          style="width: 75.6vh !important;"
                          v-model="ArticleForm.description"
                          type="textarea"
                          autosize
                          placeholder="请输入描述"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24">
                      <span><i>*</i>作&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;者：</span>
                      <el-form-item prop="author">
                        <el-input
                          class="admin_tel"
                          style="width: 75.6vh !important;"
                          v-model="ArticleForm.author"
                          placeholder="请输入作者"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24">
                      <span>&nbsp;&nbsp;排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
                      <el-form-item prop="articleIndex">
                        <el-input
                          class="admin_tel"
                          style="width: 75.6vh !important;"
                          v-model="ArticleForm.articleIndex"
                          oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                          placeholder="请输入排序"
                        >
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24">
                      <span><i>*</i>关联分类：</span>
                      <el-form-item prop="categoryId">
                        <el-cascader
                          v-model="ArticleForm.categoryId"
                          :options="options"
                          style="width: 75.6vh !important;"
                          class="admin_tel input77"
                          :show-all-levels="false"
                          @change="areaNameChange"
                          :props="this.categorylogic.categoryProps"
                          :placeholder=this.ArticleForm.categoryName
                        >
                        </el-cascader>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24">
                      <span><i>*</i>启用状态：</span>
                      <el-form-item prop="isShow">
                        <el-select  class="admin_tel" style="width: 28.6vh !important;" v-model="ArticleForm.isShow" placeholder="请选择启用状态">
                            <el-option
                            v-for="item in this.articlelogic.Show"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24" :md="24" >
                      <span><i>*&nbsp;</i>缩&nbsp;略&nbsp;图：</span>
                      <el-form-item>
                          <el-upload
                          :http-request="upload"
                          action="none"
                          class="upload-demo"
                          accept="image/jpg,image/jpeg,image/png" 
                          :before-upload="handleBeforeUpload" 
                          >
                          <el-button size="small" type="primary">点击上传</el-button>
                          </el-upload>
                      </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="24" style="height: auto !important;">
                      <div v-for="(item,index) in ArticleForm.images " class="uploadimg-box" style="display: flex;align-items: center;">
                          <img :src="this.imgURL + item" alt="" style="width: 20rem;">
                          <div class="imgdelete" @click="imgdelete(index)"><el-button type="danger" round>删除</el-button></div>
                      </div>
                  </el-col>
                    <Editor :biu="contents" :handleChange="handleChange" />
                </el-row>
            </el-form>
            <div class="sureBtn">
              <el-button class="qd" @click="sureEditArticle('editArticle')" 
                ></el-button
              >
              <el-button class="qx" @click="handleClose('editArticle')" 
                ></el-button
              >
            </div>
            </el-drawer>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import articlelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import articleSearch from "@/components/searchComponents/articleSearch.vue";
  import Editor from "@/components/articleEditor"
  export default {
    components: {
        articlelist,
        pager,
        articleSearch,
        top,
        basecom,
        Editor
    },
    data() {
      return {
        ArticleForm: {
            subTitles:[],
            images:['']
        },
        articleList: [],
        page: 1,
        numTotal: 0,
        editDrawer:false,
        list:[""],
        contents:'',
        options:''
      };
    },
    mounted() {
      this.search();
    },
    methods: {
          handleBeforeUpload(file) {
            var img = file.name.substring(file.name.lastIndexOf('.') + 1)
            const suffix = img === 'jpg'
            const suffix2 = img === 'png'
            const suffix3 = img === 'jpeg'
            // const isLt1M = file.size / 1024 / 1024 < 1;
            if (!suffix && !suffix2 && !suffix3) {
                this.$message.error("只能上传图片！");
                return false
            }
            // 可以限制图片的大小
            // if (!isLt1M) {
            //     this.$message.error('上传图片大小不能超过 1MB!');
            // }
            return suffix || suffix2 || suffix3
        },
        imgdelete(index){
          this.$confirm('确定要取消添加该图片吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.ArticleForm.images.splice(index, 1)
          })
        },
        // 上传文件
        upload (file) {
          let fileobj = file.file;
          var formdata = new FormData();
          formdata.append("FileInfo", fileobj);
          var aa = 1
          this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
              this.ArticleForm.images.push(res.data.data.relativePath)
          });
        },
        areaNameChange (e) {
          this.ArticleForm.categoryId = e[e.length - 1];
        },
        addListItem() {
          this.list.push("");
        },
        deleteListItem(){
          this.list = this.list.slice(0, this.list.length-1);
        },
        handleChange: function (editor) {
          let res = editor.getHtml();
          this.ArticleForm.contents = res;
        },
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page) {
        this.articlelogic.searchObj.page = page;
        this.articlelogic.getArticleList().then((res) => {
          this.articleList = res.articleList;
          this.numTotal = res.total;
          this.articleList.forEach(element => {
            element.isShows = ''
            switch(element.isShow){
                case true:
                    element.isShows = "已启用";
                break;
                case false:
                    element.isShows = "未启用";
                break;
            }
          });
        });
      },
      sureEditArticle(ref) {
        let aa = this.ArticleForm;
        aa.id = this.mbID;
        delete aa.categoryName
        delete aa.crcreateDate
        delete aa.createUser
        delete aa.createDate
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.axios
              .post(this.baseURL + "Article/ArticleEdit", aa, this.headers())
              .then((res) => {
                this.resolveData(res.data, "edit");
                // if(res.data.code != 200){
                // }else{
                //   this.editDrawer = false
                // }
              });
          } else {
            return false;
          }
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      handleClose() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editDrawer = false
            this.list = [""]
            done();
          })
          .catch((_) => {});
      },
      editArticle(row) {
        this.ArticleForm =  {
            subTitles:[]
        }
        let obj = { ...row };
        this.mbID = row.id;
        var aa = {
            id: this.mbID
        }
        this.ArticleForm = obj
        this.axios.post(this.baseURL + 'Article/ArticleDetails',aa,this.headers()).then(res=>{
            res.data.data.contents =  res.data.data.contents.replace('\\');
            this.ArticleForm.subTitles = res.data.data.subTitles
            this.ArticleForm.images = res.data.data.images
            this.list.length = res.data.data.subTitles.length
            this.ArticleForm.contents = res.data.data.contents
            this.ArticleForm.keyWords = res.data.data.keywords
            this.ArticleForm.description = res.data.data.description
            this.contents = res.data.data.contents
            this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
              let data = this.resolveData(res.data)
              let nodes = data.categoryList.map((item) => ({
                    value: item.id,
                    label: item.categoryName,
                    leaf: item.hasChildren === false,
                }));
                this.options = nodes;
            this.editDrawer = true  
          })
            this.resetForm("editArticle");
        })
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  