<template>
    <div style="border: 1px solid #ccc; margin:0 auto;width: 100%;height: 68vh;margin-top: 2rem;overflow: hidden;">
        <Toolbar
            style="border-bottom: 1px solid #ccc;"
            :editor="editor"
            :defaultConfig="toolbarConfig"
        />
        <Editor
            style="height: 95%; overflow-y: hidden;"
            v-model="html"
            :defaultConfig="editorConfig"
            @onCreated="onCreated"
            @onChange="handleChange"
        />
    </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default ({
  components: { Editor, Toolbar },
  props: {
    value: { type: String, required: true },
    handleChange: {
        type: Function,
        default: function () {},
    },
    biu: String, 
  },
  data() {
      return {
        editor: null,
        html: '',
        toolbarConfig: { 
        },
        editorConfig: { placeholder:'请输入内容' ,
            MENU_CONF: {
                uploadImage: {// 配置上传图片
                    customUpload: this.update
                },
                uploadVideo: {  //上传视频配置
                    customUpload: this.update
                }
            },
        },
      }
  },
  created(){    },
  methods: {
      onCreated(editor) { 
          this.editor = Object.seal(editor) 
      },
      getEditorText() {
          const editor = this.editor;
          if (editor == null) return;
      },        
      update(files,insertFn){//图片上传
          var formData = new FormData();
          formData.append('FileInfo', files);
          var aa = 1
          //根据后台提供的图片上传接口，进行接口配置和上传
          this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formData,this.headers()).then(res=>{
            insertFn( this.imgURL +  res.data.data.relativePath);
          })
      },
  },
  mounted() {
      this.$nextTick(() => {
          this.html = this.value
          if(this.biu != undefined){
            this.html = this.biu 
          }
      })
  },
  beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() 
  }
})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>