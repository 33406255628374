import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        // title: "",
    },
    Show: [
        { value: false, label: "未启用" },
        { value: true, label: "已启用" }
    ],
    rules: {
        title: [
            { required: true, message: "请输入标题", trigger: "blur" },
        ],
        keyWords: [
            { required: true, message: "请输入关键词", trigger: "blur" },
        ],
        keywords: [
            { required: true, message: "请输入关键词", trigger: "blur" },
        ],
        description: [
            { required: true, message: "请输入描述", trigger: "blur" },
        ],
        author: [
            { required: true, message: "请输入作者", trigger: "blur" },
        ],
        isShows: [
            { required: true, message: "请选择启用状态", trigger: "change" },
        ],
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "title", label: "内容标题",width:260 }, 
            { prop: "author", label: "作者",width:260},
            { prop: "categoryName", label: "分类名称",width:180},
            { prop: "isShows", label: "启用状态",width:120 },
            { prop: "articleIndex", label: "排序",width:150},
            { prop: "readCount", label: "阅读量"},
            { prop: "createUser", label: "创建用户",width:150},
            { prop: "createDate", label: "创建时间",width:200},
        ];
    },
    listHeaderye: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "title", label: "内容标题" }, 
            { prop: "author", label: "作者"},
            { prop: "categoryName", label: "分类名称"},
        ];
    },
    opHeaderye: function () {
        let header = this.listHeaderye();
        header.push({ prop: "btn", slot: "btn", label: "选择",width:150 });
        return header;
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:150 });
        return header;
    },
    getArticleList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Article/ArticleList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = { page: 1};
    }
}