<template>
    <div class="body">
      <assignRole></assignRole>
    </div>
  </template>
  
<script>
import assignRole from "@/components/userComponents/assignRole";

export default {
components: {
    assignRole,
},
};
</script>
  
  