import proxy from '../main'
import axios from 'axios'
export default {
    searchObj: {
        page: 1,
        linkName: "",
    },
    page: 1,
    TF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    rules: {
        linkName: [
            { required: true, message: "请输入友情链接名称", trigger: "blur" },
        ],
        linkIndex: [
            { required: true, message: "请输入排序值", trigger: "blur" },
        ],
        linkPath: [
            { required: true, message: "请输入友情链接地址", trigger: "blur" },
            { pattern:/^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$/, message: "请输入正确的链接地址", }
        ],
        isShow: [
            { required: true, message: "请选择是否显示", trigger: "change" },
        ]
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width:100},
            { prop: "linkName", label: "友情链接名称" }, 
            { prop: "linkPath", label: "友情链接地址",width:280},
            { prop: "isShowName", label: "显示状态名称" },
            { prop: "linkIndex", label: "排序值" },
            { prop: "createDate", label: "创建时间"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作",width:150 });
        return header;
    },
    getFriendlyList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "FriendlyLinks/FriendlyLinksList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    reset: function () {
        this.searchObj = { page: 1, linkName: ""};
    }
}