<template>
    <div class="login">
        <div class="login-box">
          <div class="login-text"></div>
          <div class="login-right">
              <el-tabs type="border-card" @tab-click="handleTabsEdit">
                <el-tab-pane label="密码登录">
                  <el-form
                    ref="userLoginform"
                    :rules="userLoginRules"
                    :model="userLoginForm"
                  >
                    <el-form-item prop="username">
                      <el-input
                        v-model.trim="userLoginForm.username"
                        prefix-icon="Iphone"
                        placeholder="用户名/手机号"
                      />
                    </el-form-item>
                    <el-form-item prop="password">
                      <el-input
                        type="password"
                        show-password
                        v-model.trim="userLoginForm.password"
                        prefix-icon="lock"
                        placeholder="请输入密码"
                      />
                    </el-form-item>
                    <el-form-item prop="verifyNum">
                      <el-input
                        maxlength="4"
                        prefix-icon="Check"
                        placeholder="请输入验证码"
                        v-model="userLoginForm.verifyNum"
                        @keyup.enter.native="login()"
                      ></el-input>
                      <signcode style="position: absolute;right: 0.5rem;" />
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              <!-- <el-tab-pane label="手机号登录">
                  <el-form
                    ref="phoneLoginform"
                    :rules="phoneLoginRules"
                    :model="phoneLoginForm"
                  >
                    <el-form-item prop="phone">
                      <el-input
                        v-model.trim="phoneLoginForm.phone"
                        placeholder="请输入手机号"
                        onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                        prefix-icon="iphone"
                      />
                    </el-form-item>
                    <el-form-item prop="signcode">
                      <el-input
                        placeholder="请输入验证码"
                        v-model.trim="phoneLoginForm.signcode"
                        @keyup.enter.native="login()"
                        prefix-icon="Check"
                      />
                      <el-button style="position: absolute;right: 0.3rem;background: none;border: none;color: #8CB3FF;" :type="btnType" @click="getCode()" :disabled="noGet">
                        <span class="countdown">{{ countdown }}秒后可再次</span>
                        获取验证码
                      </el-button>
                    </el-form-item>
                  </el-form>
                </el-tab-pane> -->
          <el-row>
            <el-col></el-col>
          </el-row>
              </el-tabs>
              <div class="login-go" @click="login()"></div>
          </div>
        </div>
        <div class="login-bottom">
          <div>
            技术支持：北京麦邦光电仪器有限公司
          </div>
          <div>
            版本号：麦邦官网V1.0.0
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import signcode from "@/components/signCode.vue";
  export default {
    components: {
      signcode,
    },
    data() {
    return {
      loginType: "0",
      btnType: "warning",
      noGet: false,
      countdown: 60,
      userLoginForm: { username: "", password: "", verifyNum: "" },
      phoneLoginForm: { phone: "", signcode: "" },
      userLoginRules: {
        username: [
          {
            required: true,
            message: "请输入用户名/手机号!",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码!",
            trigger: "blur",
          },
        ],
        verifyNum: [
          {
            required: true,
            validator: this.checkCode,
            trigger: "blur",
          },
        ],
      },
      phoneLoginRules: {
        phone: [
          { required: true, validator: this.checkPhone, trigger: "blur" },
        ],
        signcode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },
    mounted(){
      
    },
    methods: {
    checkPhone: function (rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    checkCode: function (rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else if (value !== this.loginLogic.identCode) {
        callback(new Error("验证码输入错误"));
      } else {
        callback();
      }
    },
  
    handleTabsEdit: function (obj) {
      this.loginType = obj.index;
    },
    getCode: function () {
      this.$refs["phoneLoginform"].validateField("phone", (valid) => {
        if (valid) {
          this.axios
            .get(
              this.baseURL +
                "User/SendSMSCode?phone=" +
                this.phoneLoginForm.phone
            )
            .then((res) => {
              let countdown = document.querySelector(".countdown");
              countdown.style.display = "inline";
              this.btnType = "info";
              this.countdown = 60;
              let timer = setInterval(() => {
                this.noGet = true;
                this.countdown -= 1;
                if (this.countdown === 0) {
                  this.noGet = false;
                  this.countdown = "";
                  countdown.style.display = "none";
                  this.btnType = "warning";
                  clearInterval(timer);
                }
              }, 1000);
            });
           
        }
      });
    },
  
    login: function () {
      let aa;
      let url;
      let ruleChecked;
      console.log(this.loginType)      
      if (this.loginType === "0") {
        this.$refs["userLoginform"].validate((valid) => {
          if (valid) {
            url = "User/UserLogin";
            aa = {
              userName: this.userLoginForm.username,
              pwd: this.userLoginForm.password,
            };
            ruleChecked = valid;
            console.log(ruleChecked)
            this.ruleCheckeds(ruleChecked,url,aa);
          }
        });
      }
      if (this.loginType === "1") {
        this.$refs["phoneLoginform"].validate((valid) => {
          if (valid) {
            url = "User/UserLoginWithCheckCode";
            aa = {
              phone: this.phoneLoginForm.phone,
              code: this.phoneLoginForm.signcode,
            };
            ruleChecked = valid;
            this.ruleCheckeds(ruleChecked,url,aa);
          }
        });
      }
    },
    ruleCheckeds(ruleChecked,url,aa) {
      console.log(ruleChecked)
      if (ruleChecked) {
        this.axios.post(this.baseURL + url, aa).then((response) => {
          let data = this.resolveData(response.data);
          let funlist = [];
          let funbtnlist = [];
          data.functionList.forEach((fun) => {
            if (fun.functionTypeCode == 1) {
              funbtnlist.push(fun);
            } else if (fun.functionTypeCode == 2) {
              funlist.push(fun);
            }
          });
          sessionStorage.setItem("mbDhl", "");
          sessionStorage.setItem("delArr", "");
          sessionStorage.setItem("fpid", "");
          sessionStorage.setItem("fpname", "");
          sessionStorage.setItem("menuid", "");
          sessionStorage.setItem("username", data.userInfo.userName);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("functionList", JSON.stringify(funlist));
          sessionStorage.setItem("funbtnList", JSON.stringify(funbtnlist));
          sessionStorage.setItem("roleList", JSON.stringify(data.roleList));
          sessionStorage.setItem("agencyList", JSON.stringify(data.agencyList));
          this.$message({
            message: `登录成功`,
            type: "success",
          });
          this.$router.push("/index.html");
        });
      }
    },
  },
  }
  </script>
  <style>
  @import url('../assets/login.css');
  </style>
  