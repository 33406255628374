<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <categorylist
                    :default-expanded-keys="[]"
                    :row-class-name="tableRowClassName"
                    :header-cell-style="{ 
                    background:'#000',color:'#fff'}"
                    :tableData="this.categoryList"
                    :tableHeader="this.categorylogic.listHeader()"
                    :lazyLoad="this.categorylogic.getChildCategoryList"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import categorylist from "@/components/listComponents/treeList";
export default {
    components: {
    basecom,
    categorylist,
    top
},
data() {
    return {
        categoryList:''
    };
},
mounted() {
    let aa = {
        // parentId: tree.id,
    }
    this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
        let data = this.resolveData(res.data)
        console.log(data)
        this.categoryList = data.categoryList
    })
},
methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
    },
},
};
</script>