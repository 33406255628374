<template>
    <div class="body">
      <div class="top-box-top">
          <top />
      </div>
      <div class="bottom-box">
        <basecom />
        <div class="list">
          <div class="medical-list">
              <div class="search">
                <moduleSearch
                :search="search"
                :reset="reset"
                :numTotal="numTotal"
                :addModule="addModule"
                ></moduleSearch>
              </div>
              <moduleTypelist
              :header-cell-style="{ 
              background:'#000',color:'#fff'}"
              :tableHeader="this.modulelogic.listHeader()"
              :tableData="moduleTypeList"
              :page="this.modulelogic.searchObj.page"
              :row-class-name="tableRowClassName"
              ></moduleTypelist>
              <pager
              :page="this.modulelogic.searchObj.page"
              :numTotal="numTotal"
              :handleCurrentChange="handleCurrentChange"
              >
              </pager>
          </div>
        </div>
        <el-drawer
          :model-value="aadDrawer"
          title="添加模块类型"
          :before-close="handleClose">
          <el-form ref="AddModule" :model="ModuleForm" :rules="this.modulelogic.rules" class="medical-from" style="height: 68vh;overflow: auto;">
          <el-row
            class="add-row"
            style="justify-content: space-around;height: 60vh;"
          >
            <el-col :span="24" :md="24">
              <span><i>*</i>类型名称：</span>
              <el-form-item prop="moduleTypeName">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="ModuleForm.moduleTypeName"
                  maxlength="20"
                  placeholder="请输入模块类型名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
           
            <el-col :span="24" :md="24">
              <span><i>*</i>模板类型：</span>
              <el-form-item prop="templateType">
                <el-select  class="admin_tel" style="width: 75.6vh !important;" v-model="ModuleForm.templateType" placeholder="请选择模板类型">
                    <el-option
                    v-for="item in this.templateTypeList"
                    :key="item.key"
                    :label="item.value"
                    :placeholder=ModuleForm.templateTypeName
                    :value="item.key">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span>&nbsp;模板排序：</span>
              <el-form-item>
                <el-input
                class="admin_tel"
                style="width: 75.6vh !important;"
                v-model="ModuleForm.moduleOrder"
                maxlength="20"
                oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                placeholder="请输入模板排序"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>模&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;板：</span>
              <el-form-item prop="moduleTemplate">
                <el-input
                  class="admin_tel"
                  autosize
                  style="width: 75.6vh !important;"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入模板"
                  v-model="ModuleForm.moduleTemplate">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
          <div class="sureBtn">
            <el-button class="qd" @click="sureAddModule('AddModule')" 
              ></el-button
            >
            <el-button class="qx" @click="handleClose('AddModule')" 
              ></el-button
            >
          </div>
        </el-drawer>
      </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import moduleTypelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import moduleSearch from "@/components/searchComponents/moduleSearch.vue";
  export default {
        components: {
            moduleTypelist,
            pager,
            moduleSearch,
            top,
            basecom,
        },
        name: "add",
        data() {
            return {
                ModuleForm: {},
                moduleTypeList: [],
                aadDrawer:false,
                page: 1,
                numTotal: 0,
                templateTypeList:[],
                templateType:'',
                content:'',
                articleForm:{},
             
            }
        },
      mounted() {  
        this.search();
      },
      methods: {
        tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
        },
        handleCurrentChange: function (page = 1) {
            this.modulelogic.searchObj.page = page;
            this.modulelogic.getModuleList().then((res) => {
                this.moduleTypeList = res.moduleTypeList;
                this.numTotal = res.total;
            });
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.aadDrawer = false
              done();
            })
            .catch(_ => {});
        },
        addModule(){
          this.aadDrawer = true
          this.axios.get(this.baseURL + 'ModuleType/TemplateTypeList',this.headers()) .then((res) => {
              this.templateTypeList = res.data.data.enums
          })
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
        sureAddModule(ref) {
          this.$refs[ref].validate((valid) => {
            if (valid) {
                this.axios
                .post(this.baseURL + "ModuleType/ModuleTypeAdd", this.ModuleForm, this.headers())
                .then((res) => {
                    this.resolveData(res.data, "add");
                    this.moduleTypeList.push(this.ModuleForm)
                });
            } else {
                return false;
            }
          });
        },
      },
  };
  </script>
     
     
     