<template>
  <div class="body">
    <div class="top-box-top">
        <top />
    </div>
    <div class="bottom-box">
      <basecom />
      <div class="list">
        <div class="medical-list">
            <roleList
                :row-class-name="tableRowClassName"
                :header-cell-style="{ 
                background:'#000',color:'#fff'}"
                :tableData="this.rolelogic.roleList()"
                :tableHeader="this.rolelogic.opHeader()"
                :lazyLoad="this.rolelogic.getChildRoleList"
            >
                <template v-slot:btn="slotProps">
                  <el-button type="primary" @click.stop="addrole(slotProps.row)">
                      添加子角色
                  </el-button>
                </template>
            </roleList>
        </div>
      </div>
      <el-drawer
        :model-value="addRoleDial"
        title="添加子角色"
        :before-close="nosureAddRole">
          <div style="margin-top: 20vh;height: 20vh;">
              <el-form
                ref="addroleForm"
                :model="roleForm"
                :rules="this.rolelogic.rules"
              > <el-row
              class="add-row"
            >
            <el-col :span="24" :md="20">
                <span><i>*</i>角色名称：</span>
                <el-form-item prop="roleName">
                  <el-input
                    class="admin_tel"
                    v-model="roleForm.roleName"
                    maxlength="30"
                    placeholder="请输入角色名称"
                  >
                  </el-input>
                </el-form-item>
              </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="sureBtn" style="margin-top: 17%;">
            <el-button class="qx" @click="nosureAddRole('addroleForm')" type="info"
              ></el-button
            >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <el-button class="qd" @click="sureAddRole('addroleForm')" type="primary"
              ></el-button
            >
          </div>
      </el-drawer>
    </div>
  </div>
  </template>
<script>
import basecom from "@/components/base";
import top from "@/components/top.vue";
import roleList from "@/components/listComponents/treeList";
export default {
  components: {
    roleList,
    top,
    basecom
  },
  data() {
    return {
      addRoleDial: false,
      roleName: "",
      roleForm: {},
      roleObj: {},
    };
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
    },
    sureAddRole(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.roleForm.rpid = this.roleObj.id;
          this.axios
            .post(this.baseURL + "Role/RoleAdd", this.roleForm, this.headers())
            .then((res) => {
              this.resolveData(res.data, "add");
              this.addRoleDial = false;
            });
        } else {
          return false;
        }
      });
    },
    nosureAddRole(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addRoleDial = false;
          done();
        })
        .catch((_) => {});
    },

    addrole(row) {
      this.roleObj = row;
      this.addRoleDial = true;
      this.resetForm("addroleForm");
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>