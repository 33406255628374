<template>
    <div class="body">
       <add></add>
   </div>
</template>
 
<script>
import add from "@/components/articleComponents/add";

export default {
   name: "addUser",
   components: {
       add,
   },
};
</script>
 
 