<template>
  <div class="body">
    <div class="top-box-top">
        <top />
    </div>
    <div class="bottom-box">
      <basecom />
      <div class="list">
        <div class="medical-list">
            <div class="search">
              <userSearch
                :search="search"
                :reset="reset"
                :numTotal="numTotal"
                :addUser="addUser"
                ></userSearch>
            </div>
            <userlist
            :header-cell-style="{ 
            background:'#000',color:'#fff'}"
            :tableHeader="this.userlogic.listHeader()"
            :tableData="userList"
            :page="this.userlogic.searchObj.page"
            :row-class-name="tableRowClassName"
            ></userlist>
            <pager
            :page="this.userlogic.searchObj.page"
            :numTotal="numTotal"
            :handleCurrentChange="handleCurrentChange"
            >
            </pager>
        </div>
      </div>
      <el-drawer
        :model-value="aadDrawer"
        title="添加用户"
        :before-close="handleClose">
        <el-form ref="AddUser" :model="UserFrom" :rules="this.userlogic.rules" class="medical-from" style="height: 68vh;overflow: auto;">
        <el-row
          class="add-row"
          style="justify-content: space-around;height: 60vh;"
        >
          <el-col :span="24" :md="10">
            <span><i>*</i>用户名称：</span>
            <el-form-item prop="userName">
              <el-input
                class="admin_tel"
                style="width: 28.6vh !important;"
                v-model="UserFrom.userName"
                maxlength="20"
                placeholder="请输入用户名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="10">
            <span><i>*</i>手&nbsp;&nbsp;机&nbsp;&nbsp;号：</span>
            <el-form-item prop="mobile">
              <el-input
                class="admin_tel"
                style="width: 28.6vh !important;"
                v-model="UserFrom.mobile"
                maxlength="11"
                placeholder="请输入手机号"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="10">
            <span><i>*</i>身份证号：</span>
            <el-form-item prop="idCard">
              <el-input
                class="admin_tel"
                style="width: 28.6vh !important;"
                v-model="UserFrom.idCard"
                maxlength="18"
                placeholder="请输入身份证号"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="10">
            <span><i>*</i>电子邮箱：</span>
            <el-form-item prop="email">
              <el-input
                class="admin_tel"
                style="width: 28.6vh !important;"
                v-model="UserFrom.email"
                type="email"
                placeholder="请输入电子邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="10">
            <span class="electrode"><i>*</i>短信接收状态：</span>
            <el-form-item prop="mobileReceiveStatus">
                <el-select
                  style="width: 28.6vh !important;"
                  v-model="UserFrom.mobileReceiveStatus"
                  placeholder="请选择"
                  class="admin_tel"
                >
                  <el-option
                    v-for="item in this.userlogic.TF"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="10">
            <span class="electrode"><i>*</i>邮箱接收状态：</span>
            <el-form-item prop="emailReceiveStatus">
              <el-select
                style="width: 28.6vh !important;"
                v-model="UserFrom.emailReceiveStatus"
                placeholder="请选择"
                class="admin_tel"
              >
                <el-option
                  v-for="item in this.userlogic.TF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        </el-form>
        <div class="sureBtn">
          <el-button class="qd" @click="sureAddUser('AddUser')" 
            ></el-button
          >
          <el-button class="qx" @click="nosureAddUser('AddUser')" 
            ></el-button
          >
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import userlist from "@/components/listComponents/pagerList";
import top from "@/components/top.vue";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
    components: {
      userlist,
      pager,
      userSearch,
      top,
      basecom
    },
    name: "add",
    data() {
    return {
        UserFrom: {
            emailReceiveStatus: false,
            mobileReceiveStatus: false,
        },
        userList: [],
        aadDrawer:false,
        page: 1,
        numTotal: 0,
    };
    },
    mounted() {  
      this.search();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
        },
        handleCurrentChange: function (page = 1) {
            this.userlogic.searchObj.page = page;
            this.userlogic.getUserList().then((res) => {
                this.userList = res.userList;
                this.numTotal = res.total;
            });
        },
        handleClose(done) {
          this.$confirm('确认关闭？')
            .then(_ => {
              this.aadDrawer = false
              done();
            })
            .catch(_ => {});
        },
        addUser(){
            this.aadDrawer = true
        },
        reset() {
            window.location.reload()
        },
        search() {
            this.handleCurrentChange();
        },
        sureAddUser(ref) {
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    this.axios
                    .post(this.baseURL + "User/UserAdd", this.UserFrom, this.headers())
                    .then((res) => {
                        this.resolveData(res.data, "add");
                    });
                } else {
                    return false;
                }
            });
        },
        nosureAddUser() {
            this.$confirm("确认要关闭吗？")
            .then((_) => {
              this.aadDrawer = false
              done();
            })
            .catch((_) => {});
        },
    },
};
</script>
   
   
   