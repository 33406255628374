<template>
    <canvas
      id="s-canvas"
      :width="contentWidth"
      :height="contentHeight"
      @click="changeCode"
    />
  </template>
  <script>
  export default {
    props: {
      fontSizeMin: {
        type: Number,
        default: 28,
      },
      fontSizeMax: {
        type: Number,
        default: 36,
      },
      backgroundColorMin: {
        type: Number,
        default: 180,
      },
      backgroundColorMax: {
        type: Number,
        default: 240,
      },
      colorMin: {
        type: Number,
        default: 30,
      },
      colorMax: {
        type: Number,
        default: 100,
      },
      lineColorMin: {
        type: Number,
        default: 40,
      },
      lineColorMax: {
        type: Number,
        default: 180,
      },
      dotColorMin: {
        type: Number,
        default: 0,
      },
      dotColorMax: {
        type: Number,
        default: 255,
      },
      contentWidth: {
        type: Number,
        default: 90,
      },
      contentHeight: {
        type: Number,
        default: 40,
      },
    },
    mounted() {
      this.changeCode();
    },
    methods: {
      changeCode: function () {
        let num = "";
        for (let i = 0; i < 4; i++) {
          num += Math.floor(Math.random() * 10);
        }
        this.loginLogic.identCode = num;
  
        this.drawPic();
      },
      // 生成一个随机数
      randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      },
      // 生成一个随机的颜色
      randomColor(min, max) {
        var r = this.randomNum(min, max);
        var g = this.randomNum(min, max);
        var b = this.randomNum(min, max);
        return "rgb(" + r + "," + g + "," + b + ")";
      },
      drawPic() {
        console.log(this.loginLogic.identCode);
        var canvas = document.getElementById("s-canvas");
        var ctx = canvas.getContext("2d");
        ctx.textBaseline = "bottom";
        // 绘制背景
        ctx.fillStyle = this.randomColor(
          this.backgroundColorMin,
          this.backgroundColorMax
        );
        ctx.fillRect(0, 0, this.contentWidth, this.contentHeight);
        // 绘制文字
        for (let i = 0; i < this.loginLogic.identCode.length; i++) {
          this.drawText(ctx, this.loginLogic.identCode[i], i);
        }
        this.drawLine(ctx);
        this.drawDot(ctx);
      },
      drawText(ctx, txt, i) {
        ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax);
        ctx.font =
          this.randomNum(this.fontSizeMin, this.fontSizeMax) + "px SimHei";
        var x = (i + 1) * (this.contentWidth / (this.loginLogic.identCode.length + 1));
        var y = this.randomNum(this.fontSizeMax, this.contentHeight - 5);
        var deg = this.randomNum(-30, 0);
        // 修改坐标原点和旋转角度
        ctx.translate(x, y);
        ctx.rotate((deg * Math.PI) / 180);
        ctx.fillText(txt, 0, 0);
        // 恢复坐标原点和旋转角度
        ctx.rotate((-deg * Math.PI) / 180);
        ctx.translate(-x, -y);
      },
      drawLine(ctx) {
        // 绘制干扰线
        for (let i = 0; i < 8; i++) {
          ctx.strokeStyle = this.randomColor(
            this.lineColorMin,
            this.lineColorMax
          );
          ctx.beginPath();
          ctx.moveTo(
            this.randomNum(0, this.contentWidth),
            this.randomNum(0, this.contentHeight)
          );
          ctx.lineTo(
            this.randomNum(0, this.contentWidth),
            this.randomNum(0, this.contentHeight)
          );
          ctx.stroke();
        }
      },
      drawDot(ctx) {
        // 绘制干扰点
        for (let i = 0; i < 100; i++) {
          ctx.fillStyle = this.randomColor(0, 255);
          ctx.beginPath();
          ctx.arc(
            this.randomNum(0, this.contentWidth),
            this.randomNum(0, this.contentHeight),
            1,
            0,
            2 * Math.PI
          );
          ctx.fill();
        }
      },
    },
  };
  </script>
  
  <style>
  </style>