<template>
    <div class="body">
        <add></add>
    </div>
  </template>
  <script>
  import add from "@/components/roleComponents/add";
  export default { 
  components: {
    add
  },
  };
  </script>
  
  
  
  
  