<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                    <div class="search">
                        <userSearch
                        :search="search"
                        :reset="reset"
                        :numTotal="numTotal"
                        ></userSearch>
                    </div>
                    <userlist
                    :header-cell-style="{ 
                    background:'#000',color:'#fff'}"
                    :tableHeader="this.userlogic.opHeader()"
                    :tableData="userList"
                    :page="this.userlogic.searchObj.page"
                    :row-class-name="tableRowClassName"
                    >
                        <template v-slot:btn="slotProps">
                            <el-button type="primary" @click="editrole(slotProps.row)">
                            分配角色
                            </el-button>
                        </template>
                    </userlist>
                    <pager
                    :page="this.userlogic.searchObj.page"
                    :numTotal="numTotal"
                    :handleCurrentChange="handleCurrentChange"
                    >
                    </pager>
                </div>
            </div>
            <el-drawer
              :model-value="editRole"
              title="请选择需要分配的角色"
              :before-close="nosureEditRole"
            >
              <el-row class="assign-row">
                <el-col>
                  <el-tree
                    :data="roleList"
                    ref="roleTree"
                    node-key="id"
                    :default-checked-keys="userRoleList"
                    :check-strictly="true"
                    :props="defaultProps"
                    @node-click="handleNodeClick"
                    show-checkbox
                  >
                  </el-tree>
                </el-col>
              
              </el-row>
              <div class="sureBtn assignBtn" style="margin-top:-0.5rem;">
                <el-button class="qx" @click="nosureEditRole('AddUser')" type="info"
                  ></el-button
                >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <el-button class="qd" @click="sureEditRole('AddUser')" type="primary"
                  ></el-button
                >
              </div>
            </el-drawer>
        </div>
  </div>
   
</template>

<script>
  import basecom from "@/components/base";
import userlist from "@/components/listComponents/pagerList";
import top from "@/components/top.vue";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  name: "assignRole",
  components: {
    userlist,
    pager,
    userSearch,
    top,
    basecom
  },
  data() {
    return {
      editRole: false,
      roleList: [],
      userList: [],
      page: 1,
      pageSize: 10,
      formList: [],
      numTotal: 0,
      mbID: "",
      userRoleList: [],
      defaultProps: {
        children: "children",
        label: "roleName",
      },
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
    handleCurrentChange: function (page = 1) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
      this.getRoleList();
    },
    search() {
      this.handleCurrentChange();
    },
    nosureEditRole() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editRole = false;
          done();
        })
        .catch((_) => {});
    },
    sureEditRole() {
      let aa = {
        userId: this.mbID,
        roleIdList: this.$refs.roleTree.getCheckedKeys(),
      };
      this.axios
        .post(this.baseURL + "User/AssignRole", aa, this.headers())
        .then((res) => {
          this.resolveData(res.data, "edit");
        });
    },
    editrole(row) {
      this.editRole = true;
      this.mbID = row.id;
      let aa = {
        id: row.id,
      };
      this.userRoleList = [];
      this.axios
        .post(this.baseURL + "User/GetUserRoleList", aa, this.headers())
        .then((res) => {
          this.$refs.roleTree.setCheckedKeys([]);
          let data = this.resolveData(res.data);
          let arr = [];
          data.roleList.map((item) => {
            arr.push(item.id);
          });
          this.userRoleList = arr;
        });
    },
    handleNodeClick() {
      console.log(this.$refs.roleTree.getCheckedKeys());
    },
    getRoleList() {
      let roles = this.getRole();
      roles.forEach((role) => {
        let aa = {
          rpid: role.id,
        };
        this.axios
          .post(this.baseURL + "Role/RoleListWithChild", aa, this.headers())
          .then((res) => {
            let data = this.resolveData(res.data);
            role.children = data.roleList;
          });
        this.roleList.push(role);
        console.log(role)
      });
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>

<style>
</style>