<template>
    <div class="body">
        <div class="top-box-top">
            <top />
        </div>
        <div class="bottom-box">
            <basecom />
            <div class="list">
                <div class="medical-list">
                  <div class="search">
                      <moduleSearch
                      :search="search"
                      :reset="reset"
                      :numTotal="numTotal"
                      ></moduleSearch>
                  </div>
                  <moduleTypelist
                  :header-cell-style="{ 
                  background:'#000',color:'#fff'}"
                  :tableHeader="this.modulelogic.opHeader()"
                  :tableData="moduleTypeList"
                  :page="this.modulelogic.searchObj.page"
                  :row-class-name="tableRowClassName"
                  >
                      <template v-slot:btn="slotProps">
                          <el-button type="warning" @click="editModule(slotProps.row)">
                              修改模块类型
                          </el-button>
                      </template>
                  </moduleTypelist>
                  <pager
                  :page="this.modulelogic.searchObj.page"
                  :numTotal="numTotal"
                  :handleCurrentChange="handleCurrentChange"
                  >
                  </pager>
                </div>
            </div>
            <el-drawer
            :model-value="editDrawer"
            title="修改模块类型"
            :before-close="handleClose">
            <el-form ref="editModule" :model="ModuleForm" :rules="this.modulelogic.rules" style="overflow: auto;height: 62vh;">
              <el-row
                class="add-row"
                style="justify-content: space-around;height: 60vh;"
              >
              <el-col :span="24" :md="24">
              <span><i>*</i>类型名称：</span>
              <el-form-item prop="moduleTypeName">
                <el-input
                  class="admin_tel"
                  style="width: 75.6vh !important;"
                  v-model="ModuleForm.moduleTypeName"
                  maxlength="20"
                  placeholder="请输入模块类型名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
           
            <el-col :span="24" :md="24">
              <span><i>*</i>模板类型：</span>
              <el-form-item prop="templateType">
                <el-select  class="admin_tel" style="width: 75.6vh !important;" v-model="ModuleForm.templateType" placeholder="请选择模板类型">
                    <el-option
                    v-for="item in this.templateTypeList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>模板排序：</span>
              <el-form-item prop="moduleOrder">
                <el-input
                class="admin_tel"
                oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
                style="width: 75.6vh !important;"
                v-model="ModuleForm.moduleOrder"
                maxlength="20"
                placeholder="请输入模板排序"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="24">
              <span><i>*</i>模&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;板：</span>
              <el-form-item prop="moduleTemplate">
                <el-input
                  class="admin_tel"
                  autosize
                  style="width: 75.6vh !important;"
                  type="textarea"
                  :rows="2"
                  :placeholder=ModuleForm.moduleTemplate
                  v-model="ModuleForm.moduleTemplate">
                </el-input>
              </el-form-item>
            </el-col>
              </el-row>
            </el-form>
            <div class="sureBtn">
              <el-button class="qd" @click="sureEditModule('editModule')" 
                ></el-button
              >
              <el-button class="qx" @click="handleClose('editModule')" 
                ></el-button
              >
            </div>
            </el-drawer>
        </div>
    </div>
  </template>
  <script>
  import basecom from "@/components/base";
  import moduleTypelist from "@/components/listComponents/pagerList";
  import top from "@/components/top.vue";
  import pager from "@/components/listComponents/pager.vue";
  import moduleSearch from "@/components/searchComponents/moduleSearch.vue";
  export default {
    components: {
      moduleTypelist,
      pager,
      moduleSearch,
      top,
      basecom
    },
    data() {
      return {
        ModuleForm: {},
        moduleTypeList: [],
        templateTypeList:[],
        page: 1,
        numTotal: 0,
        TF: [
          { value: false, label: "否" },
          { value: true, label: "是" },
        ],
        editDrawer:false,
      };
    },
    mounted() {
      this.search();
    },
    methods: {
      tableRowClassName({row, rowIndex}) {
        if(rowIndex%2==1){
            return 'warning-row';
        }else{
            return 'success-row';
        }
      },
      handleCurrentChange: function (page, userName, email, mobile) {
        this.modulelogic.searchObj.page = page;
        this.modulelogic.getModuleList().then((res) => {
          this.moduleTypeList = res.moduleTypeList;
          this.numTotal = res.total;
        });
      },
      sureEditModule(ref) {
        let aa = this.ModuleForm;
        delete this.ModuleForm.createDate
        delete this.ModuleForm.createUser
        delete this.ModuleForm.templateTypeName
        aa.id = this.mbID;
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.axios
              .post(this.baseURL + "ModuleType/ModuleTypeEdit", aa, this.headers())
              .then((res) => {
                this.resolveData(res.data, "edit");
              });
          } else {
            return false;
          }
        });
      },
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
      handleClose() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.editDrawer = false
            done();
          })
          .catch((_) => {});
      },
      editModule(row) {
        this.axios.get(this.baseURL + 'ModuleType/TemplateTypeList',this.headers()) .then((res) => {
          this.resolveData(res.data)
          this.templateTypeList = res.data.data.enums
          let obj = { ...row };
          this.ModuleForm = obj;
          this.ModuleForm.template = this.ModuleForm.templateTypeName
          this.mbID = row.id;
          this.editDrawer = true
          this.resetForm("editModule");
        })
      },
      reset() {
        window.location.reload()
      },
      search() {
        this.handleCurrentChange();
      },
    },
  };
  </script>
  