<template>
  <div class="top-box-top">
      <top />
  </div>
  <div class="bottom-box">
    <basecom />
    <div class="list">
      <div class="medical-list">
        <categorylist
          :default-expanded-keys="[]"
          :row-class-name="tableRowClassName"
          :header-cell-style="{ 
          background:'#000',color:'#fff'}"
          :tableData="this.categoryList"
          :tableHeader="this.categorylogic.opHeader()"
          :lazyLoad="this.categorylogic.getChildCategoryList"
        >
            <template v-slot:btn="slotProps">
            <el-button
              type="primary"
              @click.stop="addCategory(slotProps.row.id, slotProps.row.functionName)"
            >
                添加子分类
            </el-button>
            </template>
        </categorylist>
      </div>
    </div>
    <el-drawer
    :model-value="addCategoryDial"
    title="添加子分类"
    :before-close="nosureAddCategory"
    >
      <el-form 
        ref="addCategory" 
        :model="categoryFrom" 
        :rules="this.categorylogic.rules"  
        style="overflow: auto;height: 65vh;"
        >
        <el-row
          class="article-add-row"
          style="justify-content: none !important;height: 60vh;"
        >
          <el-col :span="24" :md="20">
            <span><i>*</i>分类名称：</span>
            <el-form-item prop="categoryName">
              <el-input
                class="admin_tel"
                v-model="categoryFrom.categoryName"
                maxlength="128"
                placeholder="请输入分类名称"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
              <span><i>*</i>分类编码：</span>
            <el-form-item prop="categoryCode">
              <el-input
              class="admin_tel input77"
              v-model="categoryFrom.categoryCode"
              maxlength="30"
              placeholder="请输入分类编码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>分类标题：</span>
            <el-form-item prop="title">
              <el-input
              class="admin_tel input77"
              v-model="categoryFrom.title"
              maxlength="128"
              placeholder="请输入分类标题"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span style="font-size: 1.25rem;"><i>*</i>分类关键词：</span>
            <el-form-item prop="keyWords">
              <el-input
              class="admin_tel input77"
              v-model="categoryFrom.keyWords"
              placeholder="请输入分类关键词"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20" style="height: auto;"> 
              <span><i>*</i>分类描述：</span>
            <el-form-item prop="description" style="height: auto;">
              <el-input
              class="admin_tel input77"
              type="textarea"
              autosize
              v-model="categoryFrom.description"
              placeholder="请输入分类描述"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
              <span>&nbsp;&nbsp;排&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;序：</span>
            <el-form-item>
              <el-input
              class="admin_tel input77"
              v-model="categoryFrom.categoryOrder"
              maxlength="30"
              oninput="value=value.replace(/[^\.\d]/g,'').replace('.','')"
              placeholder="请输入排序"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>显示状态：</span>
            <el-form-item prop="isShow">
              <el-select  class="admin_tel" v-model="categoryFrom.isShow" placeholder="请选择显示状态">
                  <el-option
                  v-for="item in this.categorylogic.TF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span style="font-size: 0.95rem;"><i>*</i>是否设置主分类：</span>
            <el-form-item prop="isMainCategory">
              <el-select  class="admin_tel" v-model="categoryFrom.isMainCategory" placeholder="请选择是否设置主分类">
                  <el-option
                  v-for="item in this.categorylogic.EF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span><i>*</i>激活状态：</span>
            <el-form-item prop="activateStatus">
              <el-select  class="admin_tel" v-model="categoryFrom.activateStatus" placeholder="请选择激活状态">
                  <el-option
                  v-for="item in this.isShowList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span style="font-size: 1.28rem;"><i>*</i>是否为首页：</span>
            <el-form-item prop="isIndex">
              <el-select  class="admin_tel" v-model="categoryFrom.isIndex" placeholder="请选择是否为首页">
                  <el-option
                  v-for="item in this.categorylogic.LF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span style="font-size: 0.88rem;"><i>*</i>是否显示头部导航：</span>
            <el-form-item prop="isTopNav">
              <el-select  class="admin_tel" v-model="categoryFrom.isTopNav" placeholder="请选择是否显示头部导航">
                  <el-option
                  v-for="item in this.categorylogic.PF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20">
            <span style="font-size: 0.88rem;"><i>*</i>是否显示底部导航：</span>
            <el-form-item prop="isBottomNav">
              <el-select  class="admin_tel" v-model="categoryFrom.isBottomNav" placeholder="请选择是否显示底部导航">
                  <el-option
                  v-for="item in this.categorylogic.QF"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                  </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20" style="justify-content: start;height: auto;">
            <span><i>*</i>icon图标：</span>
            <el-form-item>
              <el-upload
              :http-request="uploadicon"
              action="none"
              class="upload-demo"
              accept="image/jpg,image/jpeg,image/png" 
              :before-upload="handleBeforeUploadicon" 
              >
              <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <img v-if="iconimg" :src="this.imgURL + this.categoryFrom.icon" alt="" class="upload-icon">
            </el-form-item>
          </el-col>
          <el-col :span="24" :md="20" style="justify-content: start;height: auto;">
            <span style="font-size: 1.35rem;"><i>*</i>&nbsp;缩&nbsp;&nbsp;略&nbsp;&nbsp;图：</span>
            <el-form-item>
              <el-upload
              :http-request="upload"
              action="none"
              class="upload-demo"
              accept="image/jpg,image/jpeg,image/png" 
              :before-upload="handleBeforeUpload" 
              >
              <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
              <img v-if="thumbnailimg" :src="this.imgURL + this.categoryFrom.thumbnail" alt="" class="upload-icon">
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureAddCategory('addCategory')" type="info"></el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureAddCategory('addCategory')" type="primary"></el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import basecom from "@/components/base";
import top from "@/components/top";
import categorylist from "@/components/listComponents/treeList";
export default {
  components: { categorylist,basecom,top },
  data() {
    return {
      categoryList:'',
      addCategoryDial: false,
      categoryFrom: {
      },
      mbagencyid: "",
      options:[],
      isShowList:[],
      iconimg:false,
      thumbnailimg:false
    };
  },
  mounted(){
    let aa = {
    }
    this.axios.post(this.baseURL + 'Category/CategoryList', aa, this.headers()).then(res => {
        let data = this.resolveData(res.data)
        this.categoryList = data.categoryList
    })
  },
  methods: {
      handleBeforeUploadicon(file) {
          var img = file.name.substring(file.name.lastIndexOf('.') + 1)
          const suffix = img === 'jpg'
          const suffix2 = img === 'png'
          const suffix3 = img === 'jpeg'
          // const isLt1M = file.size / 1024 / 1024 < 1;
          if (!suffix && !suffix2 && !suffix3) {
              this.$message.error("只能上传图片！");
              return false
          }
          // 可以限制图片的大小
          // if (!isLt1M) {
          //     this.$message.error('上传图片大小不能超过 1MB!');
          // }
          return suffix || suffix2 || suffix3
      },
      handleBeforeUpload(file) {
          var img = file.name.substring(file.name.lastIndexOf('.') + 1)
          const suffix = img === 'jpg'
          const suffix2 = img === 'png'
          const suffix3 = img === 'jpeg'
          // const isLt1M = file.size / 1024 / 1024 < 1;
          if (!suffix && !suffix2 && !suffix3) {
              this.$message.error("只能上传图片！");
              return false
          }
          // 可以限制图片的大小
          // if (!isLt1M) {
          //     this.$message.error('上传图片大小不能超过 1MB!');
          // }
          return suffix || suffix2 || suffix3
      },
    // 上传文件
    uploadicon (file) {
      let fileobj = file.file;
      var formdata = new FormData();
      formdata.append("FileInfo", fileobj);
      var aa = 1
      this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
        this.categoryFrom.icon = res.data.data.relativePath
        this.iconimg = true
      });
    },
    upload(file){
      let fileobj = file.file;
      var formdata = new FormData();
      formdata.append("FileInfo", fileobj);
      var aa = 1
      this.axios.post(this.baseURL + 'UpLoadFile/UpLoadFile?UpLoadModuleType=' + aa,formdata,this.headers()).then(res=>{
        this.categoryFrom.thumbnail = res.data.data.relativePath
        this.thumbnailimg = true
      });
    },
    tableRowClassName({row, rowIndex}) {
      if(rowIndex%2==1){
          return 'warning-row';
      }else{
          return 'success-row';
      }
    },
    nosureAddCategory() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addCategoryDial = false;
          this.iconimg = false
          this.thumbnailimg = false
          done();
        })
        .catch((_) => {});
    },
    sureAddCategory(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let categoryFrom = this.categoryFrom;
          this.axios
            .post(
              this.baseURL + "Category/CategoryAdd",
              categoryFrom,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "add");
              // if(res.data.code != 200){
              // }else{
              //   this.addCategoryDial = false
              // }
            });
        } else {
          return false;
        }
      });
    },
    addCategory(id, name) {
      this.addCategoryDial = true;
      this.axios.get(this.baseURL + 'Category/ActivateStatusList',this.headers()) .then((res) => {
        this.isShowList = res.data.data.enums
      })
      this.categoryFrom = {};
      this.categoryFrom.parentId = id;
      this.resetForm("addCategory");
    },
    //清除验证提示方法
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>
  