<template>
    <div class="medical-list">
      <agencylist
        :row-class-name="tableRowClassName"
        :header-cell-style="{
        background:'#000',color:'#fff'}"
        :tableData="this.agencylogic.agencyList()"
        :tableHeader="this.agencylogic.opHeader()"
        :lazyLoad="this.agencylogic.getChildAgencyList"
      >
        <template v-slot:btn="scope">
            <el-button
              type="primary"
              v-if="scope.row.id != emptyId"
              @click.stop="editAgency(scope.row)"
            >
              修改机构
            </el-button>
          </template>
      </agencylist>
    </div>
      <el-dialog
      :model-value="addAgencyDial"
        center
        class="medical-dialogs"
        :before-close="nosureEditAgency"
      >
      <div class="dialog-titile">修改机构</div>
          <el-form 
            ref="addAgency" 
            :model="agencyFrom" 
            :rules="this.agencylogic.rules"  
            style="overflow: auto;height: 36.2rem;"
            >
            <el-row
              class="add-row"
            >
            <el-col :span="24" :md="20">
              <span><i>*</i>上级机构：</span>
              <el-form-item prop="apid">
                <el-cascader
                  class="admin_tel"
                  v-model="agencyFrom.apid"
                  :options="options"
                  :show-all-levels="false"
                  :props="this.agencylogic.agencyProps"
                  @change="agencyChange"
                  :placeholder=agencyFrom.apName
                  >
                  </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
              <span><i>*</i>机构名称：</span>
              <el-form-item prop="agencyName">
                <el-input
                  class="admin_tel"
                  v-model="agencyFrom.agencyName"
                  maxlength="30"
                  placeholder="请输入机构名称"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
              <span><i>*</i>机构编码：</span>
              <el-form-item prop="agencyCode">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.agencyCode"
                maxlength="30"
                placeholder="请输入机构编码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
              <span>&nbsp;<i>*</i>&nbsp;经&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
              <el-form-item prop="longitude">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.longitude"
                maxlength="30"
                placeholder="请输入经度"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
                <span>&nbsp;<i>*</i>&nbsp;纬&nbsp;&nbsp;&nbsp;&nbsp;度：</span>
              <el-form-item prop="latitude">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.latitude"
                maxlength="30"
                placeholder="请输入纬度"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="20">
                <span>&nbsp;<i>*</i>&nbsp;地&nbsp;&nbsp;&nbsp;&nbsp;址：</span>
              <el-form-item prop="address">
                <el-input
                class="admin_tel input77"
                v-model="agencyFrom.address"
                maxlength="30"
                placeholder="请输入地址"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          </el-form>
        <div class="sureBtn">
          <el-button class="qx" @click="nosureEditAgency('addAgency')" type="info"
            ></el-button
          >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <el-button class="qd" @click="sureEditAgency('addAgency')" type="primary"
            ></el-button
          >
        </div>
      </el-dialog>
   
  </template>
  <script>
  import agencylist from "@/components/listComponents/treeList";
  
  export default {
    components: { agencylist },
    data() {
      return {
        addAgencyDial: false,
        agencyFrom: {},
        mbagencyid: "",
        options:[]
      };
    },
    mounted(){
      var aa = JSON.parse(window.sessionStorage.getItem("agencyList")) 
        this.agencyList = aa;
        let nodes = this.agencyList.map((item) => ({
          value: item.id,
          label: item.agencyName,
          leaf: item.hasChildren === false,
        }));
        this.options = nodes;
    },
    methods: {
      agencyChange(e) {
        this.agencyFrom.apid = e[e.length - 1];
        let aa = {
          agencyName: '',
          agencyCode: '',
          apid: this.agencyFrom.apid
        }
        this.axios
          .post(this.baseURL + "Agency/AgencyList", aa, this.headers())
          .then((res) => {
            this.resolveData(res.data);
          });
       },
      tableRowClassName({row, rowIndex}) {
          if(rowIndex%2==1){
              return 'warning-row';
          }else{
              return 'success-row';
          }
      },
      nosureEditAgency() {
        this.$confirm("确认要关闭吗？")
          .then((_) => {
            this.addAgencyDial = false;
            done();
          })
          .catch((_) => {});
      },
      sureEditAgency(ref) {
        this.$refs[ref].validate((valid) => {
          if (valid) {
            this.axios
              .post(
                this.baseURL + "Agency/AgencyEdit",
                this.agencyFrom,
                this.headers()
              )
              .then((res) => {
                this.resolveData(res.data, "add");
              });
          } else {
            return false;
          }
        });
      },
      editAgency(row) {
        this.addAgencyDial = true;
        let obj = { ...row };
        this.agencyFrom = {
          apName:obj.apName,
          agencyName:obj.agencyName,
          agencyCode:obj.agencyCode,
          longitude:obj.longitude,
          latitude:obj.latitude,
          address:obj.address,
          apid:obj.apid,
          id:obj.id
        }
        this.resetForm("addAgency");
      },
      //清除验证提示方法
      resetForm(refName) {
        if (this.$refs[refName]) {
          this.$refs[refName].resetFields();
        }
      },
    },
  };
  </script>
  