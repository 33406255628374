import proxy from '../main'
export default {
    rules: {
        agencyName: {
            required: true,
            message: "请输入机构名称",
            trigger: "blur",
        },
        longitude: {
            required: true,
            message: "请输入经度",
            trigger: "change",
        },
        latitude: {
            required: true,
            message: "请输入纬度",
            trigger: "change",
        },
        address: {
            required: true,
            message: "请输入机构地址",
            trigger: "blur",
        },
        agencyCode: {
            required: true,
            message: "请输入机构编码",
            trigger: "blur",
        },
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号",width:'100' },
            { prop: "agencyName", label: "机构名称",width:'280'},
            { prop: "agencyCode", label: "机构编码",width:'120' },
            { prop: "longitude", label: "经度",width:'150' },
            { prop: "latitude", label: "纬度",width:'150' },
            { prop: "address", label: "机构地址"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    agencyProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
            if (node.root != true) {
                let aa = {
                    page: 1,
                    agencyName: '',
                    agencyCode: '',
                    agencyParentId: node.value
                }
                proxy.axios.post(proxy.baseURL + "Agency/AgencyList", aa, proxy.headers()).then((res) => {
                    let data = proxy.resolveData(res.data)
                    let childList = data.agencyList
                    let nodes = childList.map(item => ({
                        value: item.id,
                        label: item.agencyName,
                        leaf: item.hasChildren === false
                    }))
                    resolve(nodes)
                })
            }
        }
    },
    agencyList: function () {
        return proxy.getAgency();
    },
    getChildAgencyList(tree, treeNode, resolve) {
        let aa = {
            agencyParentId: tree.id,
        }
        proxy.axios.post(proxy.baseURL + 'Agency/AgencyList', aa, proxy.headers()).then(res => {
            let data = proxy.resolveData(res.data)
            resolve(data.agencyList)
        })
    },
}