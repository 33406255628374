import proxy from '../main'
export default {
    categoryapid:'',
    TF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    EF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    RF:[
        { value: 1, label: "分类" },
        { value: 2, label: "文章" }
    ],
    PF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    QF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    LF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    FL: [
        { value: 1, label: "文章" },
        { value: 2, label: "分类" }
    ],
    rules: {
        categoryName: {
            required: true,
            message: "请输入分类名称",
            trigger: "change",
        },
        title: {
            required: true,
            message: "请输入分类标题",
            trigger: "change",
        },
        categoryCode: {
            required: true,
            message: "请输入分类编码",
            trigger: "change",
        },
        keyWords: {
            required: true,
            message: "请输入分类关键词",
            trigger: "change",
        },
        description: {
            required: true,
            message: "请输入分类描述",
            trigger: "change",
        },
        isShow: [
            { required: true, message: "请选择显示状态", trigger: "change" },
        ],
        isIndex: [
            { required: true, message: "请选择是否为首页", trigger: "change" },
        ],
        isMainCategory: [
            { required: true, message: "请选择是否设置主分类", trigger: "change" },
        ],
        isTopNav: [
            { required: true, message: "请选择是否显示头部导航", trigger: "change" },
        ],
        isBottomNav: [
            { required: true, message: "请选择是否显示底部导航", trigger: "change" },
        ],
        activateStatus: [
            { required: true, message: "请选择激活状态", trigger: "change" },
        ],
    },
    designrules:{
        moduleName: {
            required: true,
            message: "请输入模块名称",
            trigger: "blur",
        },
        templateType: {
            required: true,
            message: "请选择模板类型",
            trigger: "change",
        },
        moduleTypeId: {
            required: true,
            message: "请选择模块类型",
            trigger: "change",
        },
    },
    modulerules:{
        moduleName: {
            required: true,
            message: "请输入模型名称",
            trigger: "change",
        },
        moduleTypeId: [
            { required: true, message: "请选择模块类型", trigger: "change" },
        ],
        moduleDataType: [
            { required: true, message: "请选择模块数据类型", trigger: "change" },
        ],
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号",width:'100' },
            { prop: "categoryName", label: "分类名称",width:'300', align: "left"},
            { prop: "categoryCode", label: "分类编码",width:'150' },
            { prop: "parentCategoryName", label: "上级分类名称",width:'250' },
            { prop: "isShowName", label: "显示状态",width:'120' },
            { prop: "isIndexName", label: "是否为首页",width:'120' },
            { prop: "isMainCategoryName", label: "是否主分类",width:'120' },
            { prop: "categoryOrder", label: "排序",width:'120' },
            { prop: "activateStatusName", label: "激活状态"},
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    categoryProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
            if (node.root != true) {
                let aa = {
                    parentId: node.value
                }
                    proxy.axios.post(proxy.baseURL + "Category/CategoryList", aa, proxy.headers()).then((res) => {
                        let data = proxy.resolveData(res.data)
                        let childList = data.categoryList
                        let nodes = childList.map(item => ({
                            value: item.id,
                            label: item.categoryName,
                            leaf: item.hasChildren === false
                        }))
                        resolve(nodes)
                    })
            }
        }
    },
    categoryList: function () {
      
    },
    getChildCategoryList(tree, treeNode, resolve) {
        let aa = {
            parentId: tree.id,
        }
        proxy.axios.post(proxy.baseURL + 'Category/CategoryList', aa, proxy.headers()).then(res => {
            let data = proxy.resolveData(res.data)
            resolve(data.categoryList)
        })
    },
   
}