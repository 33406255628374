<template>
    <div class="medical-list">
        <loglist
        class="customer-table"
        :header-cell-style="{ 
        background:'#000',color:'#fff'}"
        :tableHeader="this.recordlog.listHeader()"
        :tableData="logList"
        :row-class-name="tableRowClassName"
        :page="this.recordlog.searchObj.page"
        ></loglist>
        <pager
        :page="this.recordlog.searchObj.page"
        :numTotal="numTotal"
        :handleCurrentChange="handleCurrentChange"
        >
        </pager>
    </div>
</template>
<script>
import loglist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
export default {
    components: {
        loglist,
        pager,
    },
    data() {
        return {
            logList: [],
            page: 1,
            numTotal: 0,
        };
    },
    mounted() {
        this.handleCurrentChange();
    },
    methods: {
        tableRowClassName({row, rowIndex}) {
            if(rowIndex%2==1){
                return 'warning-row';
            }else{
                return 'success-row';
            }
        },
        handleCurrentChange: function (page = 1) {
            this.recordlog.searchObj.page = page;
            this.recordlog.getSysList().then((res) => {
                this.logList = res.logList;
                this.numTotal = res.total;
            });
        },
    },
    };
</script>